import { useEffect, useState, Fragment, useRef } from "react";
import axios from "axios";
import { API_BASE_URL } from "../config";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { Button } from "react-bootstrap";

const PaymentReportTable = ({ formData }) => {
  const tableRef = useRef(null);
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleString().replace(/[/:]/g, "_");
  const [paymentReportData, setPaymentReportData] = useState([]);

  useEffect(() => {
    const getPaymentReportData = async () => {
      try {
        const fromDateStr = formData.fromDate.toISOString().split("T")[0];
        const toDateStr = formData.toDate.toISOString().split("T")[0];
        const response = await axios.get(
          `${API_BASE_URL}/api/getPaymentReportData?fromDate=${fromDateStr}&toDate=${toDateStr}`
        );
        setPaymentReportData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getPaymentReportData();
  }, [formData]);

  const totalSum = paymentReportData.reduce(
    (acc, row) => {
      acc.amount += row.amount;
      return acc;
    },
    {
      amount: 0,
    }
  );

  return (
    <Fragment>
      <DownloadTableExcel
        filename={"PaymentReport_" + formattedDate}
        sheet="Payment_Report"
        currentTableRef={tableRef.current}
      >
        <Button> Export excel </Button>
      </DownloadTableExcel>
      <div className="table-responsive">
        <table
          ref={tableRef}
          className="table table-bordered table-hover table-striped table-info mt-3"
        >
          <thead>
            <tr>
              <th>Invoice No</th>
              <th>Invoice Date</th>
              <th>Mode of Payment</th>
              <th>Ledger</th>
              <th>Outstanding Balance</th>
              <th>Narration</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {paymentReportData.map((row, index) => (
              <tr key={index}>
                <td>{row.invoice_no}</td>
                <td>{row.invoice_date}</td>
                <td>{row.mop}</td>
                <td>{row.ledger}</td>
                <td>{row.outstanding_balance}</td>
                <td>{row.narration}</td>
                <td>{row.amount}</td>
              </tr>
            ))}
            <tr>
              <th colSpan={6}>Total</th>
              <th>{totalSum.amount.toFixed(2)}</th>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default PaymentReportTable;
