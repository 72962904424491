import React, { Fragment } from "react";
import setBodyColor from "../setBodyColor";
import DashboardItem from "../components/DashboardItem";

const Billing = () => {
  setBodyColor({ color: "white" });

  const dashboardItems = [
    { title: "Purchase", navigateTo: "/purchase" },
    { title: "Sales", navigateTo: "/sales" },
    { title: "Purchase Order", navigateTo: "/purchaseOrder" },
    { title: "SalesOrder", navigateTo: "/salesOrder" },
  ];

  return (
    <Fragment>
      <div className="row text-center m-5">
        {dashboardItems.map((item, index) => (
          <DashboardItem
            key={index}
            title={item.title}
            navigateTo={item.navigateTo}
          />
        ))}
      </div>
    </Fragment>
  );
};

export default Billing;
