import { Fragment, useEffect, useState, useMemo, useCallback } from "react";
import setBodyColor from "../setBodyColor";
import InvoiceHeaderEditTable from "../components/InvoiceHeaderEditTable";
import PurchaseInvoiceItemsEditTable from "../components/PurchaseInvoiceItemsEditTable";
import { Row, Col, Button } from "react-bootstrap";
import PurchaseInvoiceFooterEditTable from "../components/PurchaseInvoiceFooterEditTable";
import axios from "axios";
import OuterBodyModal from "../components/modals/OuterBodyModal";
import { API_BASE_URL } from "../config";
import { useParams, useNavigate } from "react-router-dom";

const PurchaseEdit = () => {
  useEffect(() => {
    setBodyColor({ color: "white" });
  }, []);

  const navigate = useNavigate();
  const { invoice_no } = useParams();

  const initialHeaderFormData = {
    invoiceNo: invoice_no,
    invoiceDate: new Date(),
    name: "",
    phoneNo: "",
    altPhoneNo: "",
    city: "",
    address: "",
    os: "",
  };

  const initialItem = useMemo(
    () => ({
      sNo: "",
      productCode: "",
      productName: "",
      height: "",
      width: "",
      qty: "",
      totalSqft: "",
      purchasePrice: "",
      salesPrice: "",
      mrp: "",
      cost: "",
      purchaseInclusive: "",
      salesInclusive: "",
      grossAmt: "",
      taxable: "",
      cgstP: "",
      cgst: "",
      sgstP: "",
      sgst: "",
      igstP: "",
      igst: "",
      discountP: "",
      discount: "",
      subTotal: "",
    }),
    []
  );

  const [formDataHeader, setFormDataHeader] = useState(initialHeaderFormData);
  const [items, setItems] = useState([initialItem]);
  const [validationError, setValidationError] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formDataFooter, setFormDataFooter] = useState({
    discountType: null,
    discountInput: "",
    discountOnTotal: "",
    mop: null,
    otherExpenses: "",
    tax: { value: "GST", label: "GST" },
  });

  const billAmountTemp = Number(
    items.reduce((total, item) => total + Number(item.subTotal), 0).toFixed(2) -
      Number(
        (formDataFooter.discountOnTotal !== ""
          ? Number(formDataFooter.discountOnTotal)
          : 0
        ).toFixed(2)
      ) +
      Number(
        (formDataFooter.otherExpenses !== ""
          ? Number(formDataFooter.otherExpenses)
          : 0
        ).toFixed(2)
      )
  );

  const billAmount = Number(billAmountTemp.toFixed());

  const totalAmt = Number(
    items.reduce((total, item) => total + Number(item.subTotal), 0).toFixed(2)
  );

  const roundOff = Number(
    (billAmount - Number(billAmountTemp.toFixed(2))).toFixed(2)
  );

  const handleTaxChange = (selectedOption) => {
    // Update the tax value in formDataFooter state
    setFormDataFooter((prevData) => ({
      ...prevData,
      tax: selectedOption,
    }));

    let cgst = 0;
    let sgst = 0;
    let igst = 0;

    const updatedItems = items.map((item) => {
      if (selectedOption.value === "GST") {
        cgst = ((Number(item.taxable) * item.cgstP) / 100).toFixed(2);
        sgst = ((Number(item.taxable) * item.sgstP) / 100).toFixed(2);
      } else if (selectedOption.value === "IGST") {
        igst = ((Number(item.taxable) * item.igstP) / 100).toFixed(2);
      }
      return {
        ...item,
        cgst,
        sgst,
        igst,
      };
    });
    setItems(updatedItems);
  };

  const handleCloseErrorModal = useCallback(() => {
    setShowErrorModal(false);
  }, []);

  const onClickSubmit = async () => {
    if (
      items.length === 0 ||
      items.some((item) => !isItemValid(item)) ||
      !formDataHeader.invoiceNo ||
      !formDataHeader.invoiceDate ||
      !formDataHeader.name
    ) {
      setValidationError("Please fill in all item details.");
      setShowErrorModal(true);
      return;
    }
    setIsSubmitting(true);
    try {
      const res = await axios.post(`${API_BASE_URL}/api/addPurchaseInvoice`, {
        type: "edit",
        invoiceNo: invoice_no,
        formDataHeader: formDataHeader,
        items: items,
        formDataFooter: formDataFooter,
        totalAmt: totalAmt,
        billAmount: billAmount,
        roundOff: roundOff,
      });
      if (res.data.message) {
        console.log("success");
        // window.location.reload();
        navigate(`/purchase`);
      } else if (res.data.error) {
        // console.log("error: " + res.data.error);
        setValidationError("Error: " + res.data.error);
        setShowErrorModal(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const isItemValid = (item) => {
    return (
      item.productCode &&
      item.productName &&
      item.height &&
      item.width &&
      item.qty &&
      item.totalSqft &&
      item.purchasePrice &&
      item.grossAmt &&
      item.taxable &&
      item.subTotal
    );
  };

  return (
    <Fragment>
      <div className="m-2">
        <h1>Purchase Invoice</h1>
        <InvoiceHeaderEditTable
          type="purchase"
          formData={formDataHeader}
          updateFormData={setFormDataHeader}
        ></InvoiceHeaderEditTable>
        <PurchaseInvoiceItemsEditTable
          invoiceNo={invoice_no}
          items={items}
          initialItem={initialItem}
          updateItems={setItems}
          formDataFooter={formDataFooter}
        ></PurchaseInvoiceItemsEditTable>
        <PurchaseInvoiceFooterEditTable
          invoiceNo={invoice_no}
          formData={formDataFooter}
          updateFormData={setFormDataFooter}
          totalAmt={totalAmt}
          handleTaxChange={handleTaxChange}
        ></PurchaseInvoiceFooterEditTable>
        <Row>
          <Col className="mt-3 text-end">
            <span>Total Amount: </span>
            <span>{totalAmt}</span>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 text-end">
            <span>Discount: </span>
            <span>
              -
              {formDataFooter.discountOnTotal
                ? parseFloat(formDataFooter.discountOnTotal).toFixed(2)
                : "0.00"}
            </span>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 text-end">
            <span>Other Expenses: </span>
            <span>
              {formDataFooter.otherExpenses
                ? parseFloat(formDataFooter.otherExpenses).toFixed(2)
                : "0.00"}
            </span>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 text-end">
            <span>Round Off: </span>
            <span>{roundOff}</span>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 text-end">
            <span className="billAmountLabel">Bill Amount: </span>
            <span className="billAmountValue">{billAmount}</span>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 text-end">
            <Button onClick={onClickSubmit} disabled={isSubmitting}>
              {isSubmitting ? "Updating..." : "Update"}
            </Button>
          </Col>
        </Row>
      </div>
      <OuterBodyModal
        modalTitle="Alert"
        showModal={showErrorModal}
        handleClose={handleCloseErrorModal}
      >
        <Row>
          <Col xs={12} className="text-center">
            <h6 className="text-danger">{validationError}</h6>
            <Button onClick={() => handleCloseErrorModal()}>Ok</Button>
          </Col>
        </Row>
      </OuterBodyModal>
    </Fragment>
  );
};

export default PurchaseEdit;
