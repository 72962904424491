import React, { Fragment } from "react";
import setBodyColor from "../setBodyColor";
import DashboardItem from "../components/DashboardItem";

const Master = () => {
  setBodyColor({ color: "white" });

  const dashboardItems = [
    { title: "Product Categories", navigateTo: "/productCategories" },
    { title: "Product Details", navigateTo: "/productDetails" },
    { title: "Customers", navigateTo: "/customers" },
    { title: "Vendors", navigateTo: "/vendors" },
    { title: "Account Group", navigateTo: "/accountGroup" },
    { title: "Ledger Group", navigateTo: "/ledgerGroup" },
    { title: "Invoice Templates", navigateTo: "/master" },
  ];

  return (
    <Fragment>
      <div className="row text-center m-5">
        {dashboardItems.map((item, index) => (
          <DashboardItem
            key={index}
            title={item.title}
            navigateTo={item.navigateTo}
          />
        ))}
      </div>
    </Fragment>
  );
};

export default Master;
