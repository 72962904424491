import React, { useState, Fragment, useEffect, useCallback } from "react";
import Select from "react-select";
// import DatePicker from "react-datepicker";
// import { parseISO } from "date-fns";
import axios from "axios";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import OuterBodyModal from "./OuterBodyModal";
import AddEditProductCategory from "./AddEditProductCategory";
import { API_BASE_URL } from "../../config";

const AddEditProductDetails = (props) => {
  const initialFormData = {
    id: "",
    productCategoryId: null,
    productName: "",
    productCode: "",
    hsnCode: "",
    rol: "",
    igst: "",
    sgst: "",
    cgst: "",
    purchasePrice: "",
    salesPrice: "",
    mrp: "",
    purchaseInclusive: false,
    salesInclusive: false,
    expiryDate: "",
    validationError: "",
  };

  const productCategoryFormData = {
    id: "",
    name: "",
    validationError: "",
  };

  const [formData, setFormData] = useState(props.initialFormData);
  const [productCategories, setProductCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setFormData(props.initialFormData);
  }, [props.initialFormData]);

  const getProductCategoriesData = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/getProductCategories`,
        {
          params: {
            include: "id,name",
          },
        }
      );
      setProductCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProductCategoriesData();
  }, []);

  const onClickClear = () => {
    setFormData(initialFormData);
  };

  const onClickAdd = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const onSubmitProductCategory = useCallback(() => {
    setShowModal(false);
    getProductCategoriesData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (props.type === "Add Product Details") {
        const res = await axios.post(`${API_BASE_URL}/api/addProductDetails`, {
          product_category_id: formData.productCategoryId.value,
          product_name: formData.productName,
          product_code: formData.productCode,
          hsn_code: formData.hsnCode,
          rol: formData.rol,
          igst: formData.igst,
          sgst: formData.sgst,
          cgst: formData.cgst,
          purchase_price: formData.purchasePrice,
          sales_price: formData.salesPrice,
          mrp: formData.mrp,
          purchase_inclusive: formData.purchaseInclusive,
          sales_inclusive: formData.salesInclusive,
          expiry_date: formData.expiryDate,
        });
        if (res.data.message) {
          console.log("message:" + res.data.message);
          setFormData(initialFormData);
          props.onHandleSubmit();
        } else if (res.data.error) {
          console.log("error:" + res.data.error);
          setFormData({ ...formData, validationError: res.data.error });
        } else {
          setFormData({ ...formData, validationError: "Please Try again" });
        }
      } else if (props.type === "Edit Product Details") {
        const res = await axios.post(
          `${API_BASE_URL}/api/updateProductDetails`,
          {
            id: formData.id,
            product_category_id: formData.productCategoryId.value,
            product_name: formData.productName,
            product_code: formData.productCode,
            hsn_code: formData.hsnCode,
            rol: formData.rol,
            igst: formData.igst,
            sgst: formData.sgst,
            cgst: formData.cgst,
            purchase_price: formData.purchasePrice,
            sales_price: formData.salesPrice,
            mrp: formData.mrp,
            purchase_inclusive: formData.purchaseInclusive,
            sales_inclusive: formData.salesInclusive,
            expiry_date: formData.expiryDate,
          }
        );
        if (res.data.message) {
          console.log("message:" + res.data.message);
          props.onHandleSubmit();
        } else if (res.data.error) {
          console.log("error:" + res.data.error);
          setFormData({ ...formData, validationError: res.data.error });
        } else {
          setFormData({ ...formData, validationError: "Please Try again" });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Fragment>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <div className="float-end">
              <Link onClick={onClickClear}>Clear</Link>
            </div>
          </Col>
          <Col xs={10}>
            <Form.Group>
              <Form.Label>Select Product Category</Form.Label>
              <Select
                value={formData.productCategoryId}
                onChange={(option) => {
                  setFormData({
                    ...formData,
                    productCategoryId: option,
                  });
                }}
                options={productCategories.map((category) => ({
                  value: category.id,
                  label: category.name,
                }))}
                placeholder="-- Select Product Category --"
                isSearchable={true}
                isClearable={true}
                isLoading={!productCategories.length}
              />
            </Form.Group>
          </Col>
          <Col xs={2}>
            <Form.Label>Add</Form.Label>
            <Button onClick={onClickAdd}>+</Button>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Product Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Product Name"
                value={formData.productName}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    productName: event.target.value,
                  })
                }
                required
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Product Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Product Code"
                value={formData.productCode}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    productCode: event.target.value,
                  })
                }
                required
              />
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label>HSN Code</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter HSN Code"
                value={formData.hsnCode}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    hsnCode: event.target.value,
                  })
                }
              />
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label>ROL</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter ROL"
                value={formData.rol}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    rol: event.target.value,
                  })
                }
              />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group>
              <Form.Label>IGST</Form.Label>
              <Form.Control
                type="number"
                min="0"
                step="0.01"
                placeholder="Enter IGST"
                value={formData.igst}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    igst: event.target.value,
                  })
                }
              />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group>
              <Form.Label>SGST</Form.Label>
              <Form.Control
                type="number"
                min="0"
                step="0.01"
                placeholder="Enter SGST"
                value={formData.sgst}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    sgst: event.target.value,
                  })
                }
              />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group>
              <Form.Label>CGST</Form.Label>
              <Form.Control
                type="number"
                min="0"
                step="0.01"
                placeholder="Enter CGST"
                value={formData.cgst}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    cgst: event.target.value,
                  })
                }
              />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group>
              <Form.Label>Purchase Price</Form.Label>
              <Form.Control
                type="number"
                min="0"
                step="0.01"
                placeholder="Enter Purchase Price"
                value={formData.purchasePrice}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    purchasePrice: event.target.value,
                  })
                }
              />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group>
              <Form.Label>Sales Price</Form.Label>
              <Form.Control
                type="number"
                min="0"
                step="0.01"
                placeholder="Enter Sales Price"
                value={formData.salesPrice}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    salesPrice: event.target.value,
                  })
                }
              />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group>
              <Form.Label>MRP</Form.Label>
              <Form.Control
                type="number"
                min="0"
                step="0.01"
                placeholder="Enter MRP"
                value={formData.mrp}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    mrp: event.target.value,
                  })
                }
              />
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Check
              type="checkbox"
              label="Purchase Inclusive"
              checked={formData.purchaseInclusive === 1 ? true : false}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  purchaseInclusive: event.target.checked ? 1 : 0,
                })
              }
            />
          </Col>
          <Col xs={6}>
            <Form.Check
              type="checkbox"
              label="Sales Inclusive"
              checked={formData.salesInclusive === 1 ? true : false}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  salesInclusive: event.target.checked ? 1 : 0,
                })
              }
            />
          </Col>
          <Col xs={12}>
            <Form.Group>
              {/* <div className="date-picker-label">
                <Form.Label>Expiry Date</Form.Label>
                <DatePicker
                  selected={formData.expiryDate} // Parse the expiryDate string to Date object
                  onChange={(date) =>
                    setFormData({
                      ...formData,
                      expiryDate: date,
                    })
                  }
                  dateFormat="dd-MM-yyyy"
                  className="form-control"
                />
              </div> */}
              <Form.Control
                type="date"
                placeholder="Enter Expiry Date"
                value={formData.expiryDate}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    expiryDate: event.target.value,
                  })
                }
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Button className="mt-3" type="submit">
              Submit
            </Button>
          </Col>
          <Col xs={12}>
            <p className="text-danger">{formData.validationError}</p>
          </Col>
        </Row>
      </Form>
      <OuterBodyModal
        modalTitle="Add Product Category"
        showModal={showModal}
        handleClose={handleCloseModal}
      >
        <AddEditProductCategory
          initialFormData={productCategoryFormData}
          type="Add Product Category"
          onHandleSubmit={onSubmitProductCategory}
        ></AddEditProductCategory>
      </OuterBodyModal>
    </Fragment>
  );
};

export default AddEditProductDetails;
