import { Fragment, useState, useEffect, useRef, useCallback } from "react";
import OuterBodyModal from "./OuterBodyModal";
import { Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import styles from "./SearchCustomerModal.module.css";
import AddEditProductDetails from "./AddEditProductDetails";
import { API_BASE_URL } from "../../config";

const SearchProductModal = ({
  modalTitle,
  showModal,
  handleClose,
  updateItems,
  rowNo,
  formDataFooter,
  type,
}) => {
  const initialFormData = {
    id: "",
    productCategoryId: null,
    productName: "",
    productCode: "",
    hsnCode: "",
    rol: "",
    igst: "",
    sgst: "",
    cgst: "",
    purchasePrice: "",
    salesPrice: "",
    mrp: "",
    purchaseInclusive: false,
    salesInclusive: false,
    expiryDate: "",
    validationError: "",
  };
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [activeRow, setActiveRow] = useState(0);
  const tableRef = useRef(null);
  const inputRef = useRef(null);

  const onHandleSearchInput = async () => {
    try {
      const res = await axios.post(`${API_BASE_URL}/api/searchProductByInput`, {
        searchInput: searchInput,
      });
      if (res.data.message) {
        setSearchResults(res.data.message);
        setActiveRow(0); // Reset the active row index when search results change
      } else {
        setSearchResults([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();
      setActiveRow((prevActiveRow) =>
        prevActiveRow - 1 >= 0 ? prevActiveRow - 1 : 0
      );
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      setActiveRow((prevActiveRow) =>
        prevActiveRow + 1 < searchResults.length
          ? prevActiveRow + 1
          : prevActiveRow
      );
    } else if (event.key === "Enter") {
      event.preventDefault();
      updateItems((prevItems) => {
        const updatedItems = [...prevItems];
        const updatedItem = { ...updatedItems[rowNo] };
        updatedItem.productCode = searchResults[activeRow]?.product_code || "";
        updatedItem.productName = searchResults[activeRow]?.product_name || "";
        updatedItem.height = "";
        updatedItem.width = "";
        updatedItem.qty = "";
        updatedItem.totalSqft = "";
        updatedItem.purchasePrice =
          searchResults[activeRow]?.purchase_price || "";
        updatedItem.salesPrice = searchResults[activeRow]?.sales_price || "";
        updatedItem.mrp = searchResults[activeRow]?.mrp || "";
        if (type === "purchase") {
          updatedItem.cost =
            searchResults[activeRow]?.purchase_inclusive === 1
              ? (
                  searchResults[activeRow]?.purchase_price /
                  (1 + searchResults[activeRow]?.igst / 100)
                ).toFixed(2)
              : searchResults[activeRow]?.purchase_price;
        } else if (type === "sales") {
          updatedItem.cost =
            searchResults[activeRow]?.sales_inclusive === 1
              ? (
                  searchResults[activeRow]?.sales_price /
                  (1 + searchResults[activeRow]?.igst / 100)
                ).toFixed(2)
              : searchResults[activeRow]?.sales_price;
        }
        updatedItem.purchaseInclusive =
          searchResults[activeRow]?.purchase_inclusive || 0;
        updatedItem.salesInclusive =
          searchResults[activeRow]?.sales_inclusive || 0;
        updatedItem.grossAmt = "";
        updatedItem.taxable = "";
        updatedItem.cgstP = searchResults[activeRow]?.cgst || "";
        updatedItem.cgst = "";
        updatedItem.sgstP = searchResults[activeRow]?.sgst || "";
        updatedItem.sgst = "";
        updatedItem.igstP = searchResults[activeRow]?.igst || "";
        updatedItem.igst = "";
        updatedItem.discountP = "";
        updatedItem.discount = "";
        updatedItem.subTotal = "";
        updatedItems[rowNo] = updatedItem;
        return updatedItems;
      });
      handleClose();
    } else {
      onHandleSearchInput();
    }
  };

  useEffect(() => {
    setSearchResults([]); // Clear search results when modal opens or closes
    setActiveRow(0); // Reset the active row index when modal opens or closes
    setSearchInput(""); // Clear the search input when modal opens or closes
    if (showModal && inputRef.current && !showAddModal) {
      inputRef.current.focus(); // Focus the input field when the modal is opened
    }
  }, [showModal, showAddModal]);

  const handleCloseModal = useCallback(() => {
    setShowAddModal(false);
  }, []);

  const onClickAdd = () => {
    setShowAddModal(true);
  };

  const onSubmitProducts = useCallback(() => {
    setShowAddModal(false);
  }, []);

  useEffect(() => {
    if (tableRef.current) {
      // Scroll to the active row in the table
      const rowElement = tableRef.current.querySelector(
        `tr[data-index="${activeRow}"]`
      );
      if (rowElement) {
        rowElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }, [activeRow]);

  const handleClickRow = (index) => {
    setActiveRow(index);
    inputRef.current.focus();
  };

  return (
    <Fragment>
      <OuterBodyModal
        modalTitle={modalTitle}
        showModal={showModal}
        handleClose={handleClose}
      >
        <Row>
          <Col xs={10} className="mt-2">
            <Form.Control
              type="text"
              placeholder="Search by Product Code"
              value={searchInput}
              onChange={(event) => setSearchInput(event.target.value)}
              onKeyUp={handleKeyUp}
              ref={inputRef}
            />
          </Col>
          <Col xs={2}>
            <Button onClick={onClickAdd}>+</Button>
          </Col>
        </Row>
        <div className="table-responsive mt-3">
          <table className="table table-striped text-center" ref={tableRef}>
            <thead className="table-info">
              <tr>
                <th>Product Code</th>
                {/* <th>Purchase Price</th> */}
              </tr>
            </thead>
            <tbody>
              {searchResults.map((result, index) => (
                <tr
                  key={index}
                  data-index={index}
                  className={`${
                    index === activeRow ? styles["active-row"] : ""
                  } ${styles["all-rows"]}`}
                  onClick={() => handleClickRow(index)}
                >
                  <td>{result.product_code}</td>
                  {/* <td>{result.purchase_price}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </OuterBodyModal>
      <OuterBodyModal
        modalTitle="Add Product Details"
        showModal={showAddModal}
        handleClose={handleCloseModal}
      >
        <AddEditProductDetails
          initialFormData={initialFormData}
          type="Add Product Details"
          onHandleSubmit={onSubmitProducts}
        ></AddEditProductDetails>
      </OuterBodyModal>
    </Fragment>
  );
};

export default SearchProductModal;
