import { Form, Row, Col, Button } from "react-bootstrap";
import OuterBodyModal from "./OuterBodyModal";

const AlertModal = ({ showModal, handleClose, modalTitle, message }) => {
  return (
    <OuterBodyModal
      modalTitle={modalTitle}
      showModal={showModal}
      handleClose={handleClose}
    >
      <Form onSubmit={handleClose}>
        <Row>
          <Col xs={12} className="text-center">
            {message}
          </Col>
          <Col xs={12}>
            <Button type="submit" className="mt-2">
              ok
            </Button>
          </Col>
        </Row>
      </Form>
    </OuterBodyModal>
  );
};

export default AlertModal;
