import React, { Fragment } from "react";
import classes from "./Navbar.module.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Navbar = (props) => {
  const navigate = useNavigate();
  const logout = () => {
    props.onLogout();
    navigate("/");
  };
  return (
    <Fragment>
      <nav
        className={"navbar navbar-expand-lg navbar-dark"}
        style={{ backgroundColor: "#00cccc" }}
      >
        <div className={"container-fluid"}>
          <Link to="/home" className="navbar-brand">
            <img src="/images/demo_logo.png" width="50" height="50" alt="" />
          </Link>
          <button
            className={"navbar-toggler"}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#main_nav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className={"navbar-toggler-icon"}></span>
          </button>
          <div className={"collapse navbar-collapse"} id="main_nav">
            <ul className={"navbar-nav me-auto mb-2 mb-lg-0"}></ul>
            <ul className={"navbar-nav"}>
              <li className={"nav-item dropdown"} id="myDropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle text-dark"
                  data-bs-toggle="dropdown"
                >
                  <img
                    src="/images/avatar_male.jpg"
                    className={classes.img_header}
                    alt=""
                  />
                  &nbsp;&nbsp;{props.data.COMPANY_NAME}
                </Link>
                <ul className={"dropdown-menu dropdown-menu-end"}>
                  <li>
                    <Link to="/profile" className="dropdown-item text-wrap">
                      Profile
                    </Link>
                  </li>
                  <li>
                    <button
                      onClick={logout}
                      className="dropdown-item text-wrap"
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </Fragment>
  );
};

export default Navbar;
