import { useEffect, useState, Fragment, useRef } from "react";
import axios from "axios";
import { API_BASE_URL } from "../config";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { Button } from "react-bootstrap";

const ReceiptReportTable = ({ formData }) => {
  const tableRef = useRef(null);
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleString().replace(/[/:]/g, "_");
  const [receiptReportData, setReceiptReportData] = useState([]);

  useEffect(() => {
    const getReceiptReportData = async () => {
      try {
        const fromDateStr = formData.fromDate.toISOString().split("T")[0];
        const toDateStr = formData.toDate.toISOString().split("T")[0];
        const response = await axios.get(
          `${API_BASE_URL}/api/getReceiptReportData?fromDate=${fromDateStr}&toDate=${toDateStr}`
        );
        setReceiptReportData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getReceiptReportData();
  }, [formData]);

  const totalSum = receiptReportData.reduce(
    (acc, row) => {
      acc.amount += row.amount;
      return acc;
    },
    {
      amount: 0,
    }
  );

  return (
    <Fragment>
      <DownloadTableExcel
        filename={"ReceiptReport_" + formattedDate}
        sheet="Receipt_Report"
        currentTableRef={tableRef.current}
      >
        <Button> Export excel </Button>
      </DownloadTableExcel>
      <div className="table-responsive">
        <table
          ref={tableRef}
          className="table table-bordered table-hover table-striped table-info mt-3"
        >
          <thead>
            <tr>
              <th>Invoice No</th>
              <th>Invoice Date</th>
              <th>Mode of Receive</th>
              <th>Ledger</th>
              <th>Outstanding Balance</th>
              <th>Narration</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {receiptReportData.map((row, index) => (
              <tr key={index}>
                <td>{row.invoice_no}</td>
                <td>{row.invoice_date}</td>
                <td>{row.mor}</td>
                <td>{row.ledger}</td>
                <td>{row.outstanding_balance}</td>
                <td>{row.narration}</td>
                <td>{row.amount}</td>
              </tr>
            ))}
            <tr>
              <th colSpan={6}>Total</th>
              <th>{totalSum.amount.toFixed(2)}</th>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default ReceiptReportTable;
