import React, { useState, Fragment, useEffect } from "react";
import axios from "axios";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import Select from "react-select";

const AddEditVendor = (props) => {
  const initialFormData = {
    id: "",
    type: "vendor",
    name: "",
    account_code: "",
    phone_no: "",
    alt_phone_no: "",
    email: "",
    address: "",
    gstin: "",
    state: "",
    city: "",
    opening_balance: "",
    debit_credit: { value: "CREDIT", label: "CREDIT" },
    validationError: "",
  };

  const debitCredtOptions = [
    { value: "DEBIT", label: "DEBIT" },
    { value: "CREDIT", label: "CREDIT" },
  ];

  const [formData, setFormData] = useState(props.initialFormData);

  useEffect(() => {
    setFormData(props.initialFormData);
  }, [props.initialFormData]);

  const onClickClear = () => {
    setFormData(initialFormData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (props.type === "Add Vendor") {
        const res = await axios.post(`${API_BASE_URL}/api/addCustomer`, {
          customer_data: formData,
        });
        if (res.data.message) {
          console.log("message:" + res.data.message);
          setFormData(initialFormData);
          props.onHandleSubmit();
        } else if (res.data.error) {
          console.log("error:" + res.data.error);
          setFormData({ ...formData, validationError: res.data.error });
        } else {
          setFormData({ ...formData, validationError: "Please Try again" });
        }
      } else if (props.type === "Edit Vendor") {
        const res = await axios.post(`${API_BASE_URL}/api/updateCustomer`, {
          id: formData.id,
          name: formData.name,
          account_code: formData.account_code,
          phone_no: formData.phone_no,
          alt_phone_no: formData.alt_phone_no,
          email: formData.email,
          address: formData.address,
          gstin: formData.gstin,
          state: formData.state,
          city: formData.city,
          opening_balance: formData.opening_balance,
          debit_credit: formData.debit_credit.value,
        });
        if (res.data.message) {
          console.log("message:" + res.data.message);
          props.onHandleSubmit();
        } else if (res.data.error) {
          console.log("error:" + res.data.error);
          setFormData({ ...formData, validationError: res.data.error });
        } else {
          setFormData({ ...formData, validationError: "Please Try again" });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Fragment>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <div className="float-end">
              <Link onClick={onClickClear}>Clear</Link>
            </div>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                value={formData.name}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    name: event.target.value,
                  })
                }
                required
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Account Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Account Code"
                value={formData.account_code}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    account_code: event.target.value,
                  })
                }
                required
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Phone No</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Phone No"
                value={formData.phone_no}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    phone_no: event.target.value,
                  })
                }
                minLength={10}
                maxLength={10}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Alternate Phone No</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Alternate Phone No"
                value={formData.alt_phone_no}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    alt_phone_no: event.target.value,
                  })
                }
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Email"
                value={formData.email}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    email: event.target.value,
                  })
                }
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Address"
                value={formData.address}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    address: event.target.value,
                  })
                }
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>GSTIN</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter GSTIN"
                value={formData.gstin}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    gstin: event.target.value,
                  })
                }
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter State"
                value={formData.state}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    state: event.target.value,
                  })
                }
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter City"
                value={formData.city}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    city: event.target.value,
                  })
                }
              />
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label>Opening Balance</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Opening Balance"
                value={formData.opening_balance}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    opening_balance: event.target.value,
                  })
                }
                step="0.01"
                min={1}
              />
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label>Dr/Cr</Form.Label>
              <Select
                value={formData.debit_credit}
                onChange={(selectedOption) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    debit_credit: selectedOption,
                  }));
                }}
                options={debitCredtOptions}
                // placeholder="-- Select Debit/Credit --"
                isSearchable={true}
                isClearable={true}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Button className="mt-3" type="submit">
              Submit
            </Button>
          </Col>
          <Col xs={12}>
            <p className="text-danger">{formData.validationError}</p>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

export default AddEditVendor;
