import { Fragment, useEffect, useState, useCallback } from "react";
import setBodyColor from "../setBodyColor";
import { Row, Col, Form, Button } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import { API_BASE_URL } from "../config";
import DeleteConfirmationModal from "../components/modals/DeleteConfirmationModal";
import InternalLoginModal from "../components/modals/InternalLoginModal";

const Settings = () => {
  useEffect(() => {
    setBodyColor({ color: "white" });
  }, []);

  const [purchaseSelectedMop, setPurchaseSelectedMop] = useState(null);
  const [salesSelectedMop, setSalesSelectedMop] = useState(null);
  const [showDelModal, setShowDelModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const purchaseMopOptions = [
    { value: "CASH", label: "CASH" },
    { value: "CREDIT", label: "CREDIT" },
  ];
  const salesMopOptions = [
    { value: "CASH", label: "CASH" },
    { value: "CREDIT", label: "CREDIT" },
    { value: "MIXED", label: "MIXED" },
  ];

  const getMOP = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/getMopSettings`);
      setPurchaseSelectedMop({
        value: response.data.purchase,
        label: response.data.purchase,
      });
      setSalesSelectedMop({
        value: response.data.sales,
        label: response.data.sales,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMOP();
  }, []);

  const setMopSettings = async (selectedMop, type) => {
    try {
      await axios.post(`${API_BASE_URL}/api/setMopSettings`, {
        mop: selectedMop.value,
        name: type,
      });
      console.log("MOP settings saved successfully!");
    } catch (error) {
      console.log(error);
    }
  };

  const handleMopChange = (selectedOption, type) => {
    if (type === "purchase") {
      setPurchaseSelectedMop(selectedOption);
      if (selectedOption) {
        setMopSettings(selectedOption, "purchase_mop");
      }
    } else if (type === "sales") {
      setSalesSelectedMop(selectedOption);
      if (selectedOption) {
        setMopSettings(selectedOption, "sales_mop");
      }
    }
  };

  const handleFormatData = () => {
    console.log("Button Clicked");
    setShowDelModal(true);
  };

  const handleCloseModal = useCallback(() => {
    setShowDelModal(false);
    setShowLoginModal(false);
  }, []);

  const handleSubmitAlert = useCallback(async (event) => {
    event.preventDefault();
    const clickedButton = event.nativeEvent.submitter;
    const buttonValue = clickedButton.getAttribute("name");

    if (buttonValue === "Yes") {
      console.log("Yes button clicked ");
      setShowDelModal(false);
      setShowLoginModal(true);
    } else if (buttonValue === "No") {
      console.log("No button clicked ");
      setShowDelModal(false);
    }
  }, []);

  return (
    <Fragment>
      <Row className="m-2">
        <Col lg={4} className="mt-3">
          <Form.Group>
            <Form.Label>Purchase Invoice MOP</Form.Label>
            <Select
              value={purchaseSelectedMop}
              onChange={(selectedOption) =>
                handleMopChange(selectedOption, "purchase")
              }
              options={purchaseMopOptions}
              placeholder="-- Select MOP --"
              isSearchable={true}
              isClearable={true}
            />
          </Form.Group>
        </Col>
        <Col lg={4} className="mt-3">
          <Form.Group>
            <Form.Label>Sales Invoice MOP</Form.Label>
            <Select
              value={salesSelectedMop}
              onChange={(selectedOption) =>
                handleMopChange(selectedOption, "sales")
              }
              options={salesMopOptions}
              placeholder="-- Select MOP --"
              isSearchable={true}
              isClearable={true}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="m-2 mt-5">
        <Col>
          <Button onClick={handleFormatData}>Format Data</Button>
        </Col>
      </Row>
      <DeleteConfirmationModal
        showModal={showDelModal}
        handleClose={handleCloseModal}
        handleSubmitAlert={handleSubmitAlert}
      />
      <InternalLoginModal
        showModal={showLoginModal}
        handleClose={handleCloseModal}
        type="format"
        invoiceNo=""
      />
    </Fragment>
  );
};

export default Settings;
