import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import { Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import SearchCustomerModal from "./modals/SearchCustomerModal";
import setBodyColor from "../setBodyColor";
import { API_BASE_URL } from "../config";

const InvoiceHeaderTable = ({ type, formData, updateFormData }) => {
  const [showSearchModal, setShowSearchModal] = useState(false);

  useEffect(() => {
    setBodyColor({ color: "white" });
  }, []);

  useEffect(() => {
    const getInvoiceNo = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/getInvoiceSeriesByType?series_name=${type}`
        );
        updateFormData((prevFormData) => ({
          ...prevFormData,
          invoiceNo: response.data.next_number,
        }));
      } catch (error) {
        console.log(error);
      }
    };

    getInvoiceNo();
  }, [type, updateFormData]);

  const onClickSearch = () => {
    setShowSearchModal(true);
  };

  const handleCloseModal = () => {
    setShowSearchModal(false);
  };

  return (
    <Fragment>
      <div className="m-2">
        <div className="table-responsive">
          <table className={`table table-bordered shadow primaryColor`}>
            <tbody>
              <tr>
                <th>Invoice No</th>
                <th>Date</th>
                <th>Name</th>
                <th>Contact No</th>
                <th>Alternate Contact No</th>
                <th>City</th>
                <th>Address</th>
                <th>OS</th>
                <th>Search</th>
              </tr>
              <tr>
                <td>{formData.invoiceNo}</td>
                <td>
                  <DatePicker
                    selected={formData.invoiceDate}
                    onChange={(date) =>
                      updateFormData({
                        ...formData,
                        invoiceDate: date,
                      })
                    }
                    dateFormat="dd-MM-yyyy"
                    className="form-control"
                  />
                </td>
                <td>{formData.name}</td>
                <td>{formData.phoneNo}</td>
                <td>{formData.altPhoneNo}</td>
                <td>{formData.city}</td>
                <td>{formData.address}</td>
                <td>{formData.os}</td>
                <td className="text-center">
                  <Button onClick={onClickSearch}>
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <SearchCustomerModal
        modalTitle="Search"
        type={type}
        showModal={showSearchModal}
        handleClose={handleCloseModal}
        updateFormData={updateFormData}
      />
    </Fragment>
  );
};

export default InvoiceHeaderTable;
