import { useEffect, useState, Fragment, useRef } from "react";
import axios from "axios";
import { API_BASE_URL } from "../config";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { Button, Spinner } from "react-bootstrap";

const formatDate = (date) => {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return new Date(date).toLocaleDateString("en-GB", options);
};

const LedgerReportTable = ({ formData }) => {
  const tableRef = useRef(null);
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleString().replace(/[/:]/g, "_");
  const [ledgerReportData, setLedgerReportData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getLedgerReportkData = async () => {
      setLoading(true);
      try {
        const fromDateStr = formData.fromDate.toISOString().split("T")[0];
        const toDateStr = formData.toDate.toISOString().split("T")[0];
        const response = await axios.get(
          `${API_BASE_URL}/api/getledgerReportData?fromDate=${fromDateStr}&toDate=${toDateStr}&ledgerName=${formData.ledgerName}`
        );
        if (response.data.message) {
          setLedgerReportData(response.data.message);
        } else if (response.data.error) {
          setLedgerReportData([]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false); // Set loading to false after API call completes (success or error)
      }
    };
    getLedgerReportkData();
  }, [formData]);

  return (
    <Fragment>
      <DownloadTableExcel
        filename={formData.ledgerName + "_LedgerReport_" + formattedDate}
        sheet="Ledger_Report"
        currentTableRef={tableRef.current}
      >
        <Button> Export excel </Button>
      </DownloadTableExcel>

      <h1>{formData.ledgerName} Ledger Report</h1>
      <div className="table-responsive">
        <table
          ref={tableRef}
          className="table table-bordered table-hover table-striped table-info mt-3"
        >
          <thead>
            <tr>
              <th>Invoice Date</th>
              <th>Particulars</th>
              <th>Invoice Type</th>
              <th>Invoice No</th>
              <th>Debit Amount</th>
              <th>Credit Amount</th>
              <th>Balance Amount</th>
            </tr>
          </thead>
          {loading ? ( // Display loading spinner while fetching data
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
            <tbody>
              {ledgerReportData.map((row, index) => (
                <tr key={index}>
                  {/* <td>{new Date(row.invoice_date).toLocaleDateString()}</td> */}
                  <td>{formatDate(row.invoice_date)}</td>
                  <td>{row.particulars}</td>
                  <td>{row.invoice_type}</td>
                  <td>{row.invoice_no}</td>
                  <td>{row.debit_amount}</td>
                  <td>{row.credit_amount}</td>
                  <td>{row.balance_amount}</td>
                </tr>
              ))}
              <tr>
                <th colSpan={6}>Balance</th>
                <th>
                  {ledgerReportData.length > 0
                    ? ledgerReportData[ledgerReportData.length - 1]
                        .balance_amount
                    : 0}
                </th>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </Fragment>
  );
};

export default LedgerReportTable;
