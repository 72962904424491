import { useEffect, useState, Fragment, useRef } from "react";
import axios from "axios";
import { API_BASE_URL } from "../config";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { Button } from "react-bootstrap";

const SimpleProfitAndLossTable = () => {
  const tableRef = useRef(null);
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleString().replace(/[/:]/g, "_");
  const [profitAndLoss, setProfitAndLoss] = useState([]);

  const getProfitAndLossSimpleData = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/getProfitAndLossSimpleData`
      );
      setProfitAndLoss(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProfitAndLossSimpleData();
  }, []);

  return (
    <Fragment>
      <DownloadTableExcel
        filename={"ProfitAndLossSimpleReport_" + formattedDate}
        sheet="ProfitAndLoss_Simple_Report"
        currentTableRef={tableRef.current}
      >
        <Button> Export excel </Button>
      </DownloadTableExcel>
      <table
        ref={tableRef}
        className="table table-bordered table-hover table-striped table-info mt-3"
      >
        <thead>
          <tr>
            <th>Particulars</th>
            <th className="text-end">Debit</th>
            <th>Particulars</th>
            <th className="text-end">Credit</th>
          </tr>
        </thead>
        <tbody>
          {profitAndLoss.map((row, index) => (
            <tr key={index}>
              <td>{row.debit_account_group}</td>
              <td
                className="text-end"
                style={{
                  fontWeight:
                    !row.debit_account_group && !row.credit_account_group
                      ? "bold"
                      : "normal",
                }}
              >
                {row.DEBIT}
              </td>
              <td>{row.credit_account_group}</td>
              <td
                className="text-end"
                style={{
                  fontWeight:
                    !row.debit_account_group && !row.credit_account_group
                      ? "bold"
                      : "normal",
                }}
              >
                {row.CREDIT}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};

export default SimpleProfitAndLossTable;
