import React, { useState, Fragment, useEffect } from "react";
import axios from "axios";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import SearchCustomerModal from "./SearchCustomerModal";
import { API_BASE_URL } from "../../config";

const AddEditLedgerGroup = (props) => {
  const initialFormData = {
    id: "",
    ledgerGroup: "",
    accountGroup: null,
    validationError: "",
  };

  const [formData, setFormData] = useState(props.initialFormData);
  const [accountGroupOptions, setAccountGroupOptions] = useState([]);
  const [showSearchModal, setShowSearchModal] = useState(false);

  const getAccountGroupOptions = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/getAccountGroupData`,
        {
          params: {
            include: "id,account_group",
          },
        }
      );
      const options = response.data.map((accountGroup) => ({
        value: accountGroup.id,
        label: accountGroup.account_group,
      }));
      setAccountGroupOptions(options);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAccountGroupOptions();
  }, []);

  useEffect(() => {
    setFormData(props.initialFormData);
  }, [props.initialFormData]);

  const onClickClear = () => {
    setFormData(initialFormData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formData.accountGroup) {
      return setFormData({
        ...formData,
        validationError: "Please fill all details",
      });
    }
    try {
      if (props.type === "Add Ledger Group") {
        const res = await axios.post(`${API_BASE_URL}/api/addLedgerGroup`, {
          ledger_group_data: formData,
        });
        if (res.data.message) {
          console.log("message:" + res.data.message);
          setFormData(initialFormData);
          props.onHandleSubmit();
        } else if (res.data.error) {
          console.log("error:" + res.data.error);
          setFormData({ ...formData, validationError: res.data.error });
        } else {
          setFormData({ ...formData, validationError: "Please Try again" });
        }
      } else if (props.type === "Edit Ledger Group") {
        const res = await axios.post(`${API_BASE_URL}/api/updateLedgerGroup`, {
          id: formData.id,
          ledger_group: formData.ledgerGroup,
          account_group_id: formData.accountGroup.value,
        });
        if (res.data.message) {
          console.log("message:" + res.data.message);
          props.onHandleSubmit();
        } else if (res.data.error) {
          console.log("error:" + res.data.error);
          setFormData({ ...formData, validationError: res.data.error });
        } else {
          setFormData({ ...formData, validationError: "Please Try again" });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onClickSearch = () => {
    setShowSearchModal(true);
  };

  const handleCloseModal = () => {
    setShowSearchModal(false);
  };

  const handleUpdateName = async (name) => {
    setFormData({
      ...formData,
      ledgerGroup: name,
    });
    handleCloseModal();
  };

  return (
    <Fragment>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <div className="float-end">
              <Link onClick={onClickClear}>Clear</Link>
            </div>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Account Group</Form.Label>
              <Select
                value={formData.accountGroup}
                onChange={(option) => {
                  setFormData({
                    ...formData,
                    accountGroup: option,
                    ledgerGroup: "",
                  });
                }}
                options={accountGroupOptions}
                placeholder="-- Select Account Group --"
                isSearchable={true}
                isClearable={true}
              />
            </Form.Group>
          </Col>
          <Col
            xs={
              formData.accountGroup &&
              (formData.accountGroup.value === 9 ||
                formData.accountGroup.value === 10)
                ? 9
                : 12
            }
          >
            <Form.Group>
              <Form.Label>Ledger Group</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Ledger Group"
                value={formData.ledgerGroup}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    ledgerGroup: event.target.value,
                  })
                }
                required
                readOnly={
                  formData.accountGroup &&
                  (formData.accountGroup.value === 9 ||
                    formData.accountGroup.value === 10)
                }
              />
            </Form.Group>
          </Col>
          {formData.accountGroup &&
            (formData.accountGroup.value === 9 ||
              formData.accountGroup.value === 10) && (
              <Col xs={3}>
                <Form.Group>
                  <Form.Label>Search</Form.Label>
                </Form.Group>
                <Button onClick={onClickSearch}>
                  <i className="fa-solid fa-magnifying-glass"></i>
                </Button>
              </Col>
            )}
          <Col xs={12}>
            <Button className="mt-3" type="submit">
              Submit
            </Button>
          </Col>
          <Col xs={12}>
            <p className="text-danger">{formData.validationError}</p>
          </Col>
        </Row>
      </Form>
      <SearchCustomerModal
        modalTitle="Search"
        type="sales"
        showModal={showSearchModal}
        handleClose={handleCloseModal}
        handleUpdateName={handleUpdateName}
      />
    </Fragment>
  );
};

export default AddEditLedgerGroup;
