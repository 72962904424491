import { Fragment, useEffect, useState } from "react";
import setBodyColor from "../setBodyColor";
import { Row, Col, Form } from "react-bootstrap";
import Select from "react-select";

const PurchaseInvoiceFooterTable = ({
  formData,
  updateFormData,
  totalAmt,
  handleTaxChange,
}) => {
  useEffect(() => {
    setBodyColor({ color: "white" });
  }, []);

  const [selectedDiscountType, setSelectedDiscountType] = useState(null);
  const [discountInput, setDiscountInput] = useState("");
  const discountOptions = [
    { value: "Discount %", label: "Discount %" },
    { value: "Discount Price", label: "Discount Price" },
  ];
  const mopOptions = [
    { value: "CASH", label: "CASH" },
    { value: "CREDIT", label: "CREDIT" },
  ];
  const taxOptions = [
    { value: "GST", label: "GST" },
    { value: "IGST", label: "IGST" },
    { value: "Non Tax", label: "Non Tax" },
  ];

  useEffect(() => {
    if (selectedDiscountType) {
      if (selectedDiscountType.value === "Discount %") {
        const discountOnTotal =
          (parseFloat(discountInput === "" ? 0 : discountInput) *
            parseFloat(totalAmt)) /
          100;
        updateFormData((prevData) => ({
          ...prevData,
          discountType: selectedDiscountType.value,
          discountInput: discountInput,
          discountOnTotal: discountOnTotal.toFixed(2),
        }));
      } else if (selectedDiscountType.value === "Discount Price") {
        updateFormData((prevData) => ({
          ...prevData,
          discountType: selectedDiscountType.value,
          discountInput: discountInput,
          discountOnTotal: parseFloat(
            discountInput === "" ? 0 : discountInput
          ).toFixed(2),
        }));
      }
    } else {
      updateFormData((prevData) => ({
        ...prevData,
        discountType: selectedDiscountType?.value || 0,
        discountInput: discountInput,
        discountOnTotal: 0.0,
      }));
    }
  }, [selectedDiscountType, discountInput, totalAmt, updateFormData]);

  return (
    <Fragment>
      <Row>
        <Col lg={6} className="mt-3">
          <Form.Group>
            <Form.Label>Discount</Form.Label>
            <Select
              value={selectedDiscountType}
              onChange={(selectedOption) => {
                setSelectedDiscountType(selectedOption);
                if (!selectedOption) {
                  setDiscountInput("");
                }
              }}
              options={discountOptions}
              placeholder="-- Select Type --"
              isSearchable={true}
              isClearable={true}
            />
          </Form.Group>
        </Col>
        <Col lg={6} className="mt-3">
          <Form.Group>
            <Form.Label>Discount Input</Form.Label>
            <Form.Control
              type="number"
              placeholder={"Enter Discount Input"}
              value={discountInput}
              onChange={(event) => setDiscountInput(event.target.value)}
              step="0.01"
              required
              readOnly={!selectedDiscountType}
            />
          </Form.Group>
        </Col>
        <Col lg={4} className="mt-3">
          <Form.Group>
            <Form.Label>Other Expenses</Form.Label>
            <Form.Control
              type="number"
              placeholder={"Enter Expense"}
              value={formData.otherExpenses}
              onChange={(event) =>
                updateFormData((prevData) => ({
                  ...prevData,
                  otherExpenses: event.target.value,
                }))
              }
              step="0.01"
            />
          </Form.Group>
        </Col>

        <Col lg={4} className="mt-3">
          <Form.Group>
            <Form.Label>Tax</Form.Label>
            <Select
              value={formData.tax}
              onChange={(selectedOption) => {
                updateFormData((prevData) => ({
                  ...prevData,
                  tax: selectedOption,
                }));
                handleTaxChange(selectedOption);
              }}
              options={taxOptions}
              placeholder="-- Select Tax --"
              isSearchable={true}
              isClearable={true}
            />
          </Form.Group>
        </Col>
        {/* <Col lg={4} className="mt-3">
          <Form.Group>
            <Form.Label>OS</Form.Label>
            <Form.Control
              type="number"
              value={formData.os}
              onChange={(event) =>
                updateFormData((prevData) => ({
                  ...prevData,
                  os: event.target.value,
                }))
              }
              step="0.01"
              readOnly={true}
            />
          </Form.Group>
        </Col> */}
        <Col lg={4} className="mt-3">
          <Form.Group>
            <Form.Label>MOP</Form.Label>
            <Select
              value={formData.mop}
              onChange={(selectedOption) => {
                updateFormData((prevData) => ({
                  ...prevData,
                  mop: selectedOption,
                }));
              }}
              options={mopOptions}
              placeholder="-- Select MOP --"
              isSearchable={true}
              isClearable={true}
            />
          </Form.Group>
        </Col>
      </Row>
    </Fragment>
  );
};

export default PurchaseInvoiceFooterTable;
