import { Form, Row, Col, Button } from "react-bootstrap";
import OuterBodyModal from "./OuterBodyModal";

const DeleteConfirmationModal = ({
  showModal,
  handleClose,
  handleSubmitAlert,
}) => {
  return (
    <OuterBodyModal
      modalTitle="Alert"
      showModal={showModal}
      handleClose={handleClose}
    >
      <Form onSubmit={handleSubmitAlert}>
        <Row>
          <Col xs={12} className="text-center">
            Confirm Delete?
          </Col>
          <Col xs={12}>
            <Button type="submit" name="Yes" className="m-2">
              Yes
            </Button>
            <Button type="submit" name="No" className="bg-danger">
              No
            </Button>
          </Col>
        </Row>
      </Form>
    </OuterBodyModal>
  );
};

export default DeleteConfirmationModal;
