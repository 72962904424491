import React, { Fragment } from "react";
import setBodyColor from "../setBodyColor";
import DashboardItem from "../components/DashboardItem";

const Home = () => {
  setBodyColor({ color: "white" });

  const dashboardItems = [
    { title: "Dashboard", navigateTo: "/home" },
    { title: "Billing", navigateTo: "/billing" },
    { title: "CRM", navigateTo: "/home" },
    { title: "Reports", navigateTo: "/reports" },
    { title: "Marketing", navigateTo: "/home" },
    { title: "Webcam", navigateTo: "/home" },
    { title: "Accounts", navigateTo: "/accounts" },
    { title: "Master", navigateTo: "/master" },
    { title: "Settings", navigateTo: "/settings" },
  ];

  return (
    <Fragment>
      <div className="row text-center m-5">
        {dashboardItems.map((item, index) => (
          <DashboardItem
            key={index}
            title={item.title}
            navigateTo={item.navigateTo}
          />
        ))}
      </div>
    </Fragment>
  );
};

export default Home;
