import React, { Fragment } from "react";
import setBodyColor from "../setBodyColor";
import DashboardItem from "../components/DashboardItem";

const AccountsReports = () => {
  setBodyColor({ color: "white" });

  const dashboardItems = [
    { title: "Ledger Report", navigateTo: "/ledgerReport" },
    {
      title: "Outstanding Customer Report",
      navigateTo: "/outstandingCustomerReport",
    },
  ];

  return (
    <Fragment>
      <div className="row text-center m-5">
        {dashboardItems.map((item, index) => (
          <DashboardItem
            key={index}
            title={item.title}
            navigateTo={item.navigateTo}
          />
        ))}
      </div>
    </Fragment>
  );
};

export default AccountsReports;
