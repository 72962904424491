import { Fragment, useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import TablePagination from "../components/TablePagination";
import OuterBodyModal from "../components/modals/OuterBodyModal";
import { Button } from "react-bootstrap";
import AddEditVendor from "../components/modals/AddEditVendor";
import DeleteConfirmationModal from "../components/modals/DeleteConfirmationModal";
import { API_BASE_URL } from "../config";
import { read, utils } from "xlsx";
import ExportButton from "../components/ExportButton";
import DownloadTemplate from "../components/DownloadTemplate";

const Vendors = () => {
  const [customers, setCustomers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("Add Vendor");
  const [showDelModal, setShowDelModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const initialFormData = {
    id: "",
    type: "vendor",
    name: "",
    account_code: "",
    phone_no: "",
    alt_phone_no: "",
    email: "",
    address: "",
    gstin: "",
    state: "",
    city: "",
    opening_balance: "",
    debit_credit: { value: "CREDIT", label: "CREDIT" },
    validationError: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  const getCustomersData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/getCustomers`, {
        params: {
          exclude: "createdAt,updatedAt,type",
          type: "vendor",
        },
      });
      setCustomers(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCustomersData();
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setShowDelModal(false);
  }, []);

  const onClickAdd = useCallback(() => {
    setModalTitle("Add Vendor");
    setFormData(initialFormData);
    setShowModal(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickEdit = useCallback(
    (id) => {
      const getProductById = async (id) => {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/api/getCustomerById?id=${id}`
          );

          setFormData({
            ...formData,
            id: response.data.id,
            name: response.data.name,
            account_code: response.data.account_code,
            phone_no: response.data.phone_no,
            alt_phone_no: response.data.alt_phone_no,
            email: response.data.email,
            address: response.data.address,
            gstin: response.data.gstin,
            state: response.data.gstin,
            city: response.data.city,
            opening_balance: response.data.opening_balance,
            debit_credit: {
              value: response.data.debit_credit,
              label: response.data.debit_credit,
            },
          });
        } catch (error) {
          console.log(error);
        }
      };

      console.log("Edit ID: " + id);
      getProductById(id);
      setModalTitle("Edit Vendor");
      setShowModal(true);
    },
    [formData]
  );

  const onClickDel = useCallback(
    (id) => {
      setFormData({
        ...formData,
        id: id,
      });
      setShowDelModal(true);
    },
    [formData]
  );

  const onSubmitProducts = useCallback(() => {
    setShowModal(false);
    getCustomersData();
  }, []);

  const handleSubmitAlert = useCallback(
    async (event) => {
      event.preventDefault();
      const clickedButton = event.nativeEvent.submitter;
      const buttonValue = clickedButton.getAttribute("name");

      if (buttonValue === "Yes") {
        console.log("Yes button clicked and id is: " + formData.id);
        try {
          const res = await axios.post(`${API_BASE_URL}/api/delCustomer`, {
            id: formData.id,
          });
          if (res.data.message) {
            console.log(res.data.message);
          } else if (res.data.error) {
            console.log(res.data.error);
          }
        } catch (err) {
          console.log(err);
        }
        setShowDelModal(false);
        getCustomersData();
      } else if (buttonValue === "No") {
        handleCloseModal();
      }
    },
    [formData, handleCloseModal]
  );

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleFileUpload = async () => {
    try {
      if (!selectedFile) {
        alert("Please select a file to upload.");
        return;
      }

      const fileReader = new FileReader();
      fileReader.onload = async (event) => {
        const data = event.target.result;
        const workbook = read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const customersData = utils.sheet_to_json(worksheet);

        // Call the backend API to upload the customer data
        try {
          const response = await axios.post(
            `${API_BASE_URL}/api/uploadVendors`,
            {
              customersData,
            }
          );
          console.log(response.data);
          alert(response.data.message);
          getCustomersData();
          // Optionally, you can display a success message or refresh the customer data after upload.
        } catch (error) {
          console.error("Error while uploading customers:", error);
          // Display an error message to the user
        }
      };

      fileReader.readAsBinaryString(selectedFile);
    } catch (error) {
      console.error("Error during file upload:", error);
      // Display an error message to the user
    }
  };

  const memoizedCustomers = useMemo(() => customers, [customers]);
  return (
    <>
      {customers ? (
        <Fragment>
          <Button className="m-3" onClick={onClickAdd}>
            + Add
          </Button>
          <br />
          <ExportButton data={memoizedCustomers} name="Vendors" />
          <br />
          <input type="file" onChange={handleFileChange} className="m-3" />
          <Button onClick={handleFileUpload}>Upload</Button>
          <DownloadTemplate template_type="customers"></DownloadTemplate>
          <TablePagination
            key={JSON.stringify(memoizedCustomers)}
            tabledata={memoizedCustomers}
            initialRowsPerPage="5"
            showEditOption={true}
            showDeleteOption={true}
            onClickEdit={onClickEdit}
            onClickDel={onClickDel}
          ></TablePagination>
          <OuterBodyModal
            modalTitle={modalTitle}
            showModal={showModal}
            handleClose={handleCloseModal}
          >
            <AddEditVendor
              initialFormData={formData}
              type={modalTitle}
              onHandleSubmit={onSubmitProducts}
            ></AddEditVendor>
          </OuterBodyModal>
          <DeleteConfirmationModal
            showModal={showDelModal}
            handleClose={handleCloseModal}
            handleSubmitAlert={handleSubmitAlert}
          />
        </Fragment>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default Vendors;
