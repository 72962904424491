import { Fragment, useEffect, useState, useRef } from "react";
import axios from "axios";
import { API_BASE_URL } from "../config";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { Button } from "react-bootstrap";

const DetailedBalanceSheetTable = () => {
  const tableRef = useRef(null);
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleString().replace(/[/:]/g, "_");
  const [balanceSheet, setBalanceSheet] = useState([]);
  const [liabilityTotal, setLiabilityTotal] = useState(0);
  const [assetsTotal, setAssetsTotal] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/getBalanceSheetDetailedData`
        );
        setBalanceSheet(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Calculate the total amounts
    let liabilitySum = 0;
    let assetsSum = 0;

    balanceSheet.forEach((row) => {
      if (!isNaN(parseFloat(row.liability_amount))) {
        liabilitySum += parseFloat(row.liability_amount);
      }
      if (!isNaN(parseFloat(row.asset_amount))) {
        assetsSum += parseFloat(row.asset_amount);
      }
    });

    setLiabilityTotal(liabilitySum);
    setAssetsTotal(assetsSum);
  }, [balanceSheet]);

  return (
    <Fragment>
      <DownloadTableExcel
        filename={"BalanceSheetDetailedReport_" + formattedDate}
        sheet="BalanceSheet_Detailed_Report"
        currentTableRef={tableRef.current}
      >
        <Button> Export excel </Button>
      </DownloadTableExcel>
      <table
        ref={tableRef}
        className="table table-bordered table-hover table-striped table-info mt-3"
      >
        <thead>
          <tr>
            <th>Liabilities</th>
            <th className="text-end">Amount by Ledger Group</th>
            <th className="text-end">Amount by Account Group</th>
            <th>Assets</th>
            <th className="text-end">Amount by Ledger Group</th>
            <th className="text-end">Amount by Account Group</th>
          </tr>
        </thead>
        <tbody>
          {balanceSheet.map((row, index) => (
            <Fragment key={`row-${index}`}>
              <tr>
                <td>{row.liabilities}</td>
                <td className="text-end"></td>
                <td className="text-end">{row.liability_amount}</td>
                <td>{row.assets}</td>
                <td className="text-end"></td>
                <td className="text-end">{row.asset_amount}</td>
              </tr>
              {row.childData.map((childRow, childIndex) => (
                <tr key={`childRow-${index}-${childIndex}`}>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{childRow.liabilities}</td>
                  <td className="text-end">{childRow.liability_amount}</td>
                  <td className="text-end"></td>
                  <td> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{childRow.assets}</td>
                  <td className="text-end">{childRow.asset_amount}</td>
                  <td className="text-end"></td>
                </tr>
              ))}
            </Fragment>
          ))}
          <tr>
            <th>Total</th>
            <th></th>
            <th className="text-end">{liabilityTotal.toFixed(2)}</th>
            <th>Total</th>
            <th></th>
            <th className="text-end">{assetsTotal.toFixed(2)}</th>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
};

export default DetailedBalanceSheetTable;
