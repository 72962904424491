import { Fragment, useState } from "react";
import DatePicker from "react-datepicker";
import { Form, Row, Col, Button } from "react-bootstrap";
import SearchCustomerLedgerModal from "../components/modals/SearchCustomerLedgerModal";
import OutstandingCustomerReportTable from "../components/OutstandingCustomerReportTable";

const OutstandingCustomerReport = () => {
  const initialFormData = {
    fromDate: new Date(),
    toDate: new Date(),
    ledgerName: "",
    validationError: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [buttonLabel, setButtonLabel] = useState("Create Report");
  const [showLedgerModal, setShowLedgerModal] = useState(false);

  const onClickBtn = () => {
    if (!formData.fromDate || !formData.toDate) {
      setFormData({
        ...formData,
        validationError: "Please Select All",
      });
    } else {
      setFormData({
        ...formData,
        validationError: "",
      });
      buttonLabel === "Create Report"
        ? setButtonLabel("Back")
        : setButtonLabel("Create Report");
    }
  };

  const handleOpenLedgerModal = () => {
    setShowLedgerModal(true);
  };

  const handleCloseLedgerModal = () => {
    setShowLedgerModal(false);
  };

  const handleUpdateNameBalance = async (name, balance) => {
    setFormData({
      ...formData,
      ledgerName: name,
    });
    handleCloseLedgerModal();
  };

  return (
    <Fragment>
      <div className="m-3">
        {buttonLabel === "Create Report" && (
          <Row>
            <Col className="col-lg-4 col-md-4 col-12">
              <DatePicker
                selected={formData.fromDate}
                onChange={(date) =>
                  setFormData({
                    ...formData,
                    fromDate: date,
                  })
                }
                dateFormat="dd-MM-yyyy"
                className="form-control"
              />
            </Col>
            <Col className="col-lg-4 col-md-4 col-12">
              <DatePicker
                selected={formData.toDate}
                onChange={(date) =>
                  setFormData({
                    ...formData,
                    toDate: date,
                  })
                }
                dateFormat="dd-MM-yyyy"
                className="form-control"
              />
            </Col>
            <Col className="col-lg-4 col-md-4 col-12">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Select Ledger"
                  value={formData.ledgerName}
                  onChange={handleOpenLedgerModal}
                />
              </Form.Group>
            </Col>
          </Row>
        )}
        <Row>
          <Col className="col-12 text-center mt-3">
            <Button onClick={onClickBtn}>{buttonLabel}</Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="text-center mt-2">
            <h6 className="text-danger">{formData.validationError}</h6>
          </Col>
        </Row>
        {buttonLabel === "Back" && (
          <OutstandingCustomerReportTable
            formData={formData}
          ></OutstandingCustomerReportTable>
        )}
      </div>
      <SearchCustomerLedgerModal
        modalTitle="Search"
        showModal={showLedgerModal}
        handleClose={handleCloseLedgerModal}
        handleUpdateNameBalance={handleUpdateNameBalance}
      />
    </Fragment>
  );
};

export default OutstandingCustomerReport;
