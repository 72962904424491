import { Modal } from "react-bootstrap";

const OuterBodyModal = (props) => {
  const { modalTitle, showModal, handleClose, children } = props;

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header className="bg-info" closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">{children}</Modal.Body>
    </Modal>
  );
};

export default OuterBodyModal;
