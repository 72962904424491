import React from "react";
import { Button } from "react-bootstrap";

const DownloadTemplate = ({ template_type }) => {
  const handleDownloadTemplate = () => {
    let templateData = "";
    // Create a Blob with the template data and force a download
    if (template_type === "product_details") {
      templateData =
        "product_category_name,product_name,product_code,hsn_code,rol,igst,sgst,cgst,purchase_price,sales_price,mrp,purchase_inclusive,sales_inclusive,expiry_date";
    } else if (template_type === "product_category") {
      templateData = "name";
    } else if (template_type === "customers") {
      templateData =
        "name,account_code,phone_no,alt_phone_no,email,address,gstin,state,city,opening_balance,debit_credit";
    }
    const blob = new Blob([templateData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "template.csv"; // Adjust the file name and extension as needed
    link.click();
  };

  return (
    <Button className="m-3" onClick={handleDownloadTemplate}>
      Download Template
    </Button>
  );
};

export default DownloadTemplate;
