import React, { Fragment } from "react";
import setBodyColor from "../setBodyColor";
import DashboardItem from "../components/DashboardItem";

const Accounts = () => {
  setBodyColor({ color: "white" });

  const dashboardItems = [
    { title: "Payments", navigateTo: "/payments" },
    { title: "Receipts", navigateTo: "/receipts" },
    { title: "Trial Balance", navigateTo: "/trialBalance" },
    { title: "Profit And Loss", navigateTo: "/profitAndLoss" },
    { title: "Balance Sheet", navigateTo: "/balanceSheet" },
  ];

  return (
    <Fragment>
      <div className="row text-center m-5">
        {dashboardItems.map((item, index) => (
          <DashboardItem
            key={index}
            title={item.title}
            navigateTo={item.navigateTo}
          />
        ))}
      </div>
    </Fragment>
  );
};

export default Accounts;
