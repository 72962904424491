import React, { Fragment, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import OuterBodyModal from "./OuterBodyModal";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import AlertModal from "./AlertModal";

const InternalLoginModal = ({ showModal, handleClose, type, invoiceNo }) => {
  const initialFormData = {
    username: "",
    password: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [validationError, setValidationError] = useState("");
  const [showAlertModal, setShowAlertModal] = useState(false);

  const onSubmitLogin = async (event) => {
    event.preventDefault();
    if (type === "format") {
      try {
        const res = await axios.post(
          `${API_BASE_URL}/api/internalLoginToFormat`,
          {
            username: formData.username,
            password: formData.password,
          }
        );
        if (res.data.message) {
          console.log("Login Successful");
          setValidationError("");
          setFormData(initialFormData);
          handleClose();
          setShowAlertModal(true);
        } else if (res.data.error) {
          setValidationError(res.data.error);
        }
      } catch (error) {
        setValidationError(error);
      }
    } else {
      try {
        const res = await axios.post(
          `${API_BASE_URL}/api/internalLoginToDelInvoice`,
          {
            username: formData.username,
            password: formData.password,
            type: type,
            invoiceNo: invoiceNo,
          }
        );
        if (res.data.message) {
          console.log("Login Successful");
          setValidationError("");
          setFormData(initialFormData);
          handleClose();
          setShowAlertModal(true);
          window.location.reload();
        } else if (res.data.error) {
          setValidationError(res.data.error);
        }
      } catch (error) {
        setValidationError(error);
      }
    }
  };

  const handleCloseAlertModal = (event) => {
    event.preventDefault();
    setShowAlertModal(false);
  };

  return (
    <Fragment>
      <OuterBodyModal
        modalTitle="Login"
        showModal={showModal}
        handleClose={handleClose}
      >
        <Form onSubmit={onSubmitLogin}>
          <Row>
            <Col xs={12} className="text-center">
              <label>Username</label>
              <input
                type="text"
                placeholder="Enter Username"
                value={formData.username}
                onChange={(e) =>
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    username: e.target.value,
                  }))
                }
                className="form-control"
              />
            </Col>
            <Col xs={12} className="text-center">
              <label>Password</label>
              <input
                type="password"
                placeholder="Enter Password"
                value={formData.password}
                onChange={(e) =>
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    password: e.target.value,
                  }))
                }
                className="form-control"
              />
            </Col>
            <Col xs={12} className="text-center mt-3">
              <Button type="submit">
                {type === "format" ? "Format Data" : "Delete Invoice"}
              </Button>
            </Col>
          </Row>
          <div className="text-danger mt-2 text-center">{validationError}</div>
        </Form>
      </OuterBodyModal>
      <AlertModal
        showModal={showAlertModal}
        handleClose={handleCloseAlertModal}
        modalTitle="Alert"
        message="Deleted successfully"
      />
    </Fragment>
  );
};

export default InternalLoginModal;
