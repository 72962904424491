import React, { useState, Fragment, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import styles from "./Login.module.css";
import setBodyColor from "../setBodyColor";
import { API_BASE_URL } from "../config";

const Login = () => {
  const initialFormData = {
    username: "",
    password: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [validationError, setValidationError] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setBodyColor({ color: "rgb(34, 193, 195)" });
  }, []);

  const onSubmitLogin = async (e) => {
    e.preventDefault();
    const response = await axios.post(`${API_BASE_URL}/api/login`, {
      withCredentials: true,
      username: formData.username,
      password: formData.password,
    });
    if (response.data.error) {
      setValidationError(response.data.error);
    } else {
      await login(response.data.token);
      navigate("/home");
    }
  };

  return (
    <Fragment>
      <div className={styles.loginform}>
        <FormHeader title="Login" />
        <Form
          formData={formData}
          setFormData={setFormData}
          onSubmitLogin={onSubmitLogin}
          validationError={validationError}
        />
      </div>
    </Fragment>
  );
};

const FormHeader = (props) => (
  <h2 className={styles.headerTitle}>{props.title}</h2>
);

const Form = ({ formData, setFormData, onSubmitLogin, validationError }) => (
  <div>
    <form onSubmit={onSubmitLogin}>
      <FormInput
        description="Username"
        placeholder="Enter your username"
        type="text"
        value={formData.username}
        variable="username"
        setFormData={setFormData}
      />
      <FormInput
        description="Password"
        placeholder="Enter your password"
        type="password"
        value={formData.password}
        variable="password"
        setFormData={setFormData}
      />
      <FormButton title="Log in" />
      <div className="text-danger mt-2 text-center">{validationError}</div>
    </form>
  </div>
);

const FormButton = ({ title }) => (
  <div className={[styles.row, styles.buttonRow].join(" ")}>
    <button className="mb-4">{title}</button>
  </div>
);

const FormInput = ({
  description,
  placeholder,
  type,
  value,
  variable,
  setFormData,
}) => (
  <div className={styles.row}>
    <label>{description}</label>
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(e) =>
        setFormData((prevFormData) => ({
          ...prevFormData,
          [variable]: e.target.value,
        }))
      }
    />
  </div>
);

export default Login;
