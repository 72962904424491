import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import Navbar from "./NavMenu/Navbar";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../config";

const PrivateRoute = () => {
  const { jwt, logout, isAuthenticated } = useAuth();
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/api/home`, {
        withCredentials: true,
        headers: {
          "x-token": jwt,
        },
      })
      .then((res) => {
        if (res.data.error) {
          console.log("error: " + res.data.error);
          navigate("/");
        }
        console.log("success: " + res.data.username);
        console.log("isAuthenticated: " + isAuthenticated());
        setData(res.data);
      })
      .catch((err) => console.log(err));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    isAuthenticated() &&
    data && (
      <>
        <Navbar data={data} onLogout={logout} />
        <Outlet />
      </>
    )
  );
};

export default PrivateRoute;
