import { Fragment, useEffect, useState, useRef } from "react";
import axios from "axios";
import { API_BASE_URL } from "../config";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { Button } from "react-bootstrap";

const OutstandingCustomerReportTable = ({ formData }) => {
  const tableRef = useRef(null);
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleString().replace(/[/:]/g, "_");
  const [ledgerReportData, setLedgerReportData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getLedgerReportkData = async () => {
      try {
        const fromDateStr = formData.fromDate.toISOString().split("T")[0];
        const toDateStr = formData.toDate.toISOString().split("T")[0];
        const response = await axios.get(
          `${API_BASE_URL}/api/getOutstandingCustomerData?fromDate=${fromDateStr}&toDate=${toDateStr}&ledgerName=${formData.ledgerName}`
        );
        if (response.data.message) {
          setLedgerReportData(response.data.message);
        } else if (response.data.error) {
          setLedgerReportData([]);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getLedgerReportkData();
  }, [formData]);

  return (
    <Fragment>
      <DownloadTableExcel
        filename={"OutstandingCustomersReport_" + formattedDate}
        sheet="OutstandingCustomers_Report"
        currentTableRef={tableRef.current}
      >
        <Button> Export excel </Button>
      </DownloadTableExcel>
      <h1>Outstanding Customers Report</h1>
      {loading ? ( // Display "Loading" message while data is being fetched
        <div>Loading...</div>
      ) : (
        <div className="table-responsive">
          <table
            ref={tableRef}
            className="table table-bordered table-hover table-striped table-info mt-3"
          >
            <thead>
              <tr>
                <th>Invoice Date</th>
                <th>Invoice Type</th>
                <th>Invoice No</th>
                <th>Debit Amount</th>
                <th>Credit Amount</th>
                <th>Balance Amount</th>
              </tr>
            </thead>
            <tbody>
              {ledgerReportData.length > 0 &&
                ledgerReportData.map((row, index) => (
                  <tr key={index}>
                    <td>
                      {row.invoice_date.startsWith("Name: ") ? (
                        <strong>{row.invoice_date.substring(6)}</strong>
                      ) : (
                        new Date(row.invoice_date).toLocaleDateString()
                      )}
                    </td>
                    <td>{row.invoice_type}</td>
                    <td>{row.invoice_no}</td>
                    <td>{row.debit_amount}</td>
                    <td>{row.credit_amount}</td>
                    <td>{row.balance_amount}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </Fragment>
  );
};

export default OutstandingCustomerReportTable;
