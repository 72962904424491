import { createContext, useContext, useState } from "react";
import Cookies from "js-cookie";

// Create AuthContext
const AuthContext = createContext();

// Create AuthProvider component to wrap the app and provide AuthContext
export const AuthProvider = ({ children }) => {
  const [jwt, setJwt] = useState(Cookies.get("jwt") || null);

  const login = (token) => {
    setJwt(token);
    Cookies.set("jwt", token, { expires: 24 });
  };

  const logout = () => {
    setJwt(null);
    Cookies.remove("jwt");
  };

  const isAuthenticated = () => {
    return !!jwt;
  };

  return (
    <AuthContext.Provider value={{ jwt, login, logout, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

// Create useAuth hook to access AuthContext
export const useAuth = () => useContext(AuthContext);
