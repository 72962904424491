import { Fragment, useState } from "react";
import SimpleTrialBalanceTable from "../components/SimpleTrialBalanceTable";
import Select from "react-select";
import DetailedTrialBalanceTable from "../components/DetailedTrialBalanceTable";

const TrialBalance = () => {
  const [viewMode, setViewMode] = useState(null);

  const handleViewModeChange = (selectedOption) => {
    if (selectedOption) {
      setViewMode(selectedOption.value);
    } else {
      setViewMode(null);
    }
  };

  return (
    <Fragment>
      <h1>Trial Balance</h1>
      <div className="m-3">
        <label htmlFor="viewMode">View Mode:</label>
        <Select
          value={
            viewMode
              ? {
                  value: viewMode,
                  label:
                    viewMode === "simple" ? "Simple View" : "Detailed View",
                }
              : null
          }
          options={[
            { value: "simple", label: "Simple View" },
            { value: "detailed", label: "Detailed View" },
          ]}
          onChange={handleViewModeChange}
          placeholder="Select view mode"
          isClearable
        />
      </div>

      {viewMode && (
        <div className="table-responsive m-3">
          {viewMode === "simple" ? (
            <SimpleTrialBalanceTable />
          ) : (
            <DetailedTrialBalanceTable />
          )}
        </div>
      )}
    </Fragment>
  );
};

export default TrialBalance;
