import { Fragment, useState } from "react";
import DatePicker from "react-datepicker";
import { Row, Col, Button } from "react-bootstrap";
import PaymentReportTable from "../components/PaymentReportTable";

const PaymentReport = () => {
  const initialFormData = {
    fromDate: new Date(),
    toDate: new Date(),
    validationError: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [buttonLabel, setButtonLabel] = useState("Create Report");

  const onClickBtn = () => {
    if (!formData.fromDate || !formData.toDate) {
      setFormData({
        ...formData,
        validationError: "Please Select All",
      });
    } else {
      setFormData({
        ...formData,
        validationError: "",
      });
      buttonLabel === "Create Report"
        ? setButtonLabel("Back")
        : setButtonLabel("Create Report");
    }
  };

  return (
    <Fragment>
      <div className="m-3">
        {buttonLabel === "Create Report" && (
          <Row>
            <Col className="col-lg-6 col-md-6 col-12 my-1">
              <DatePicker
                selected={formData.fromDate}
                onChange={(date) =>
                  setFormData({
                    ...formData,
                    fromDate: date,
                  })
                }
                dateFormat="dd-MM-yyyy"
                className="form-control"
              />
            </Col>
            <Col className="col-lg-6 col-md-6 col-12 my-1">
              <DatePicker
                selected={formData.toDate}
                onChange={(date) =>
                  setFormData({
                    ...formData,
                    toDate: date,
                  })
                }
                dateFormat="dd-MM-yyyy"
                className="form-control"
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col className="col-12 text-center mt-3">
            <Button onClick={onClickBtn}>{buttonLabel}</Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="text-center mt-2">
            <h6 className="text-danger">{formData.validationError}</h6>
          </Col>
        </Row>
        {buttonLabel === "Back" && (
          <PaymentReportTable formData={formData}></PaymentReportTable>
        )}
      </div>
    </Fragment>
  );
};

export default PaymentReport;
