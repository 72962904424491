import { useEffect, useState, Fragment, useRef, useCallback } from "react";
import axios from "axios";
import { API_BASE_URL } from "../config";
import { FaPen, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { Button } from "react-bootstrap";
import InternalLoginModal from "./modals/InternalLoginModal";
import DeleteConfirmationModal from "./modals/DeleteConfirmationModal";

const SalesDayBook = ({ formData }) => {
  const tableRef = useRef(null);
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleString().replace(/[/:]/g, "_");
  const [dayBookData, setDayBookData] = useState([]);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showDelModal, setShowDelModal] = useState(false);
  const [invoiceNo, setInvoiceNo] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const getSalesDayBookData = async () => {
      try {
        const fromDateStr = formData.fromDate.toISOString().split("T")[0];
        const toDateStr = formData.toDate.toISOString().split("T")[0];
        const response = await axios.get(
          `${API_BASE_URL}/api/getSalesDayBookData?fromDate=${fromDateStr}&toDate=${toDateStr}&customerName=${formData.name}`
        );
        setDayBookData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getSalesDayBookData();
  }, [formData]);

  const handleOnClickEdit = (invoice_no) => {
    navigate(`/salesEdit/${invoice_no}`);
  };

  const handleOnClickDel = (invoice_no) => {
    setInvoiceNo(invoice_no);
    setShowDelModal(true);
  };

  const handleCloseModal = useCallback(() => {
    setShowDelModal(false);
    setShowLoginModal(false);
  }, []);

  const handleSubmitAlert = useCallback(async (event) => {
    event.preventDefault();
    const clickedButton = event.nativeEvent.submitter;
    const buttonValue = clickedButton.getAttribute("name");

    if (buttonValue === "Yes") {
      console.log("Yes button clicked ");
      setShowDelModal(false);
      setShowLoginModal(true);
    } else if (buttonValue === "No") {
      console.log("No button clicked ");
      setShowDelModal(false);
    }
  }, []);

  const totalSum = dayBookData.reduce(
    (acc, row) => {
      acc.total_amount += row.total_amount;
      acc.discount_on_total += row.discount_on_total;
      acc.round_off += row.round_off;
      acc.bill_amount += row.bill_amount;
      return acc;
    },
    {
      total_amount: 0,
      discount_on_total: 0,
      round_off: 0,
      bill_amount: 0,
    }
  );

  return (
    <Fragment>
      <DownloadTableExcel
        filename={"SalesDayBookReport_" + formattedDate}
        sheet="SalesDayBook_Report"
        currentTableRef={tableRef.current}
      >
        <Button> Export excel </Button>
      </DownloadTableExcel>
      <div className="table-responsive">
        <table
          ref={tableRef}
          className="table table-bordered table-hover table-striped table-info mt-3"
        >
          <thead>
            <tr>
              <th>Edit/Del</th>
              <th>Invoice No</th>
              <th>Date</th>
              <th>Customer Name</th>
              <th>Discount Type</th>
              <th>Discount Input</th>
              <th>Other Expenses</th>
              <th>MOP</th>
              <th>Tax Type</th>
              <th>OS</th>
              <th>Total Amount</th>
              <th>Discount On Total</th>
              <th>Round Off</th>
              <th>Bill Amount</th>
              <th>Created At</th>
              <th>Updated At</th>
            </tr>
          </thead>
          <tbody>
            {dayBookData.map((row, index) => (
              <tr key={index}>
                <td>
                  <FaPen
                    className="clickable text-primary m-1"
                    onClick={() => handleOnClickEdit(row.invoice_no)}
                  />
                  <FaTrash
                    className="clickable text-danger m-1"
                    onClick={() => handleOnClickDel(row.invoice_no)}
                  />
                </td>
                <td>{row.invoice_no}</td>
                <td>{row.invoice_date}</td>
                <td>{row.customer_name}</td>
                <td>{row.discount_type}</td>
                <td>{row.discount_input}</td>
                <td>{row.other_expenses}</td>
                <td>{row.mop}</td>
                <td>{row.tax_type}</td>
                <td>{row.os}</td>
                <td>{row.total_amount}</td>
                <td>{row.discount_on_total}</td>
                <td>{row.round_off}</td>
                <td>{row.bill_amount}</td>
                <td>{new Date(row.createdAt).toLocaleString()}</td>
                <td>{new Date(row.updatedAt).toLocaleString()}</td>
              </tr>
            ))}
            <tr>
              <th colSpan={10}>Total</th>
              <th>{totalSum.total_amount.toFixed(2)}</th>
              <th>{totalSum.discount_on_total.toFixed(2)}</th>
              <th>{totalSum.round_off.toFixed(2)}</th>
              <th>{totalSum.bill_amount.toFixed(2)}</th>
              <th colSpan={2}></th>
            </tr>
          </tbody>
        </table>
      </div>
      <DeleteConfirmationModal
        showModal={showDelModal}
        handleClose={handleCloseModal}
        handleSubmitAlert={handleSubmitAlert}
      />
      <InternalLoginModal
        showModal={showLoginModal}
        handleClose={handleCloseModal}
        type="sales"
        invoiceNo={invoiceNo}
      />
    </Fragment>
  );
};

export default SalesDayBook;
