import { Fragment, useEffect } from "react";
import setBodyColor from "../setBodyColor";

const Dashboard = () => {
  useEffect(() => {
    setBodyColor({ color: "white" });
  }, []);
  return (
    <Fragment>
      <h1>Wecome Dashboard</h1>
    </Fragment>
  );
};

export default Dashboard;
