import React from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Button } from "react-bootstrap";

const ExportButton = ({ name, data }) => {
  const handleExportClick = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString().replace(/[/:]/g, "_");
    const fileName = `${name}_${formattedDate}.xlsx`;

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert your data into a worksheet
    const ws = XLSX.utils.json_to_sheet(data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, name);

    // Generate the Excel file and save it
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(excelBlob, fileName);
  };

  return (
    <div>
      <Button onClick={handleExportClick} className="m-3">
        Export to Excel
      </Button>
    </div>
  );
};

export default ExportButton;
