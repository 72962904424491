import { Fragment, useState, useCallback } from "react";
import { Form, Button } from "react-bootstrap";
import SearchProductModal from "./modals/SearchProductModal";

const PurchaseInvoiceItemsTable = ({
  items,
  initialItem,
  updateItems,
  formDataFooter,
}) => {
  const [rowNo, setRowNo] = useState(0);
  const [showProductModal, setShowProductModal] = useState(false);

  const handleCloseProductModal = useCallback(() => {
    setShowProductModal(false);
  }, []);

  const handleOpenProductModal = useCallback((rowIndex) => {
    setRowNo(rowIndex);
    setShowProductModal(true);
  }, []);

  const handleAddRow = useCallback(() => {
    updateItems((prevItems) => [...prevItems, { ...initialItem }]);
  }, [initialItem, updateItems]);

  const onClickDel = (index) => {
    if (items.length === 1) {
      return; // Do not delete the only row/item
    }

    updateItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems.splice(index, 1);
      return updatedItems;
    });

    setRowNo((prevRowNo) => {
      if (prevRowNo === index) {
        // If the deleted row was the one being edited, reset the rowNo
        return 0;
      } else if (prevRowNo > index) {
        // If the deleted row was before the current rowNo, decrement the rowNo
        return prevRowNo - 1;
      } else {
        // If the deleted row was after the current rowNo, no need to update rowNo
        return prevRowNo;
      }
    });
  };

  const calculateRowValues = useCallback(
    (item) => {
      const height = parseFloat(item.height) || 0;
      const width = parseFloat(item.width) || 0;
      const qty = parseFloat(item.qty) || 0;
      const purchasePrice = parseFloat(item.purchasePrice) || 0;
      const purchaseInclusive = item.purchaseInclusive || 0;
      let cost = 0;
      const discount = parseFloat(item.discount) || 0;
      const cgstP = parseFloat(item.cgstP) || 0;
      const sgstP = parseFloat(item.sgstP) || 0;
      const igstP = parseFloat(item.igstP) || 0;
      let cgst = 0;
      let sgst = 0;
      let igst = 0;

      if (purchaseInclusive === 1) {
        cost = Number(purchasePrice / (1 + igstP / 100)).toFixed(2);
      } else {
        cost = purchasePrice;
      }
      const totalSqft = Number(height * width * qty).toFixed(2);
      const grossAmt = (Number(totalSqft) * cost).toFixed(2);
      const taxable = (Number(grossAmt) - discount).toFixed(2);
      if (formDataFooter.tax.value === "GST") {
        cgst = ((Number(taxable) * cgstP) / 100).toFixed(2);
        sgst = ((Number(taxable) * sgstP) / 100).toFixed(2);
      } else if (formDataFooter.tax.value === "IGST") {
        igst = ((Number(taxable) * igstP) / 100).toFixed(2);
      }
      const subTotal = (
        Number(taxable) +
        Number(cgst) +
        Number(sgst) +
        Number(igst)
      ).toFixed(2);

      return {
        ...item,
        cost,
        totalSqft,
        grossAmt,
        taxable,
        cgst,
        sgst,
        igst,
        subTotal,
      };
    },
    [formDataFooter.tax.value]
  );

  const onChangeItem = useCallback(
    (event, index, itemKey) => {
      const value = event.target.value;
      const parsedValue = parseFloat(value);

      updateItems((prevItems) => {
        const updatedItems = prevItems.map((item, i) => {
          if (i === index) {
            let updatedItem = { ...item, [itemKey]: value };

            if (itemKey === "discountP") {
              // When discountP changes, update the discount value
              const grossAmt = parseFloat(item.grossAmt) || 0;
              const discount = (parsedValue * grossAmt) / 100;
              updatedItem = { ...updatedItem, discount: discount.toFixed(2) };
            } else if (itemKey === "discount") {
              // When discount changes, update the discountP value
              const grossAmt = parseFloat(item.grossAmt) || 0;
              const discount = parseFloat(parsedValue) || 0;
              const discountP = (discount * 100) / grossAmt;
              updatedItem = { ...updatedItem, discountP: discountP.toFixed(2) };
            }

            return {
              ...updatedItem,
              ...calculateRowValues(updatedItem),
            };
          }
          return item;
        });
        return updatedItems;
      });
    },
    [updateItems, calculateRowValues]
  );

  return (
    <Fragment>
      <div className="m-2">
        <div className="table-responsive">
          <table className="table table-striped text-center table-bordered">
            <thead className="primaryColor">
              <tr>
                <th>S.No</th>
                <th>Product Code</th>
                <th>Product Name</th>
                <th>Height</th>
                <th>Width</th>
                <th>Quantity</th>
                <th>Total Sqft</th>
                <th>Purchase Price</th>
                <th>Cost</th>
                <th>Gross Amt</th>
                <th>Taxable</th>
                <th>CGST %</th>
                <th>CGST</th>
                <th>SGST %</th>
                <th>SGST</th>
                <th>IGST %</th>
                <th>IGST</th>
                <th>Discount %</th>
                <th>Discount</th>
                <th>Sub Total</th>
                {items.length > 1 && <th>Delete</th>}
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <Form.Control
                      type="text"
                      value={item.productCode}
                      onChange={() => handleOpenProductModal(index)}
                      className="input200W"
                    />
                  </td>
                  <td>{item.productName}</td>
                  <td>
                    <Form.Control
                      type="number"
                      value={item.height}
                      onChange={(event) => onChangeItem(event, index, "height")}
                      className="input100W"
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      value={item.width}
                      onChange={(event) => onChangeItem(event, index, "width")}
                      className="input100W"
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      value={item.qty}
                      onChange={(event) => onChangeItem(event, index, "qty")}
                      className="input100W"
                    />
                  </td>
                  <td>{item.totalSqft}</td>
                  <td>
                    <Form.Control
                      type="number"
                      value={item.purchasePrice}
                      onChange={(event) =>
                        onChangeItem(event, index, "purchasePrice")
                      }
                      className="input100W"
                    />
                  </td>
                  <td>{item.cost}</td>
                  <td>{item.grossAmt}</td>
                  <td>{item.taxable}</td>
                  <td>{item.cgstP}</td>
                  <td>{item.cgst}</td>
                  <td>{item.sgstP}</td>
                  <td>{item.sgst}</td>
                  <td>{item.igstP}</td>
                  <td>{item.igst}</td>
                  <td>
                    <Form.Control
                      type="number"
                      value={item.discountP}
                      onChange={(event) =>
                        onChangeItem(event, index, "discountP")
                      }
                      className="input100W"
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      value={item.discount}
                      onChange={(event) =>
                        onChangeItem(event, index, "discount")
                      }
                      className="input100W"
                    />
                  </td>
                  <td>{item.subTotal}</td>
                  {items.length > 1 && (
                    <td>
                      <i
                        className="fa-sharp fa-solid fa-trash text-danger clickable"
                        onClick={() => {
                          onClickDel(index);
                        }}
                      ></i>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Button onClick={handleAddRow}>Add Row</Button>
      <SearchProductModal
        modalTitle="Search Products"
        showModal={showProductModal}
        handleClose={handleCloseProductModal}
        updateItems={updateItems}
        rowNo={rowNo}
        formDataFooter={formDataFooter}
        type="purchase"
      ></SearchProductModal>
    </Fragment>
  );
};

export default PurchaseInvoiceItemsTable;
