import React from "react";
import Card from "../UI/Card";
import { useNavigate } from "react-router-dom";

const DashboardItem = ({ title, navigateTo }) => {
  const navigate = useNavigate();

  const handleItemClick = () => {
    navigate(navigateTo);
  };

  return (
    <div className="col-lg-3 col-md-4 col-12 mb-3" onClick={handleItemClick}>
      <Card>
        <div className="card-body">
          <h6>{title}</h6>
        </div>
      </Card>
    </div>
  );
};

export default DashboardItem;
