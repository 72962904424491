import { Fragment, useEffect, useState } from "react";
import setBodyColor from "../setBodyColor";
import { Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import { API_BASE_URL } from "../config";

const SalesInvoiceFooterEditTable = ({
  invoiceNo,
  formData,
  updateFormData,
  totalAmt,
  handleTaxChange,
}) => {
  useEffect(() => {
    setBodyColor({ color: "white" });
  }, []);

  const [selectedDiscountType, setSelectedDiscountType] = useState(null);
  const [bankOptions, setBankOptions] = useState(null);
  const [discountInput, setDiscountInput] = useState("");
  const discountOptions = [
    { value: "Discount %", label: "Discount %" },
    { value: "Discount Price", label: "Discount Price" },
  ];
  const mopOptions = [
    { value: "CASH", label: "CASH" },
    { value: "CREDIT", label: "CREDIT" },
    { value: "MIXED", label: "MIXED" },
  ];
  const taxOptions = [
    { value: "GST", label: "GST" },
    { value: "IGST", label: "IGST" },
    { value: "Non Tax", label: "Non Tax" },
  ];

  const getBanks = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/getBanks`);
      const data = response.data;
      const bankOptions = data.map((item) => ({
        value: item,
        label: item,
      }));
      setBankOptions(bankOptions);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBanks();
  }, []);

  useEffect(() => {
    const getInvoiceFooterData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/getInvoiceFooterData?type=sales&invoiceNo=${invoiceNo}`
        );
        updateFormData((prevFormData) => ({
          ...prevFormData,
          discountType: response.data.discount_type,
          discountInput: response.data.discount_input,
          discountOnTotal: response.data.discount_on_total,
          mop: { value: response.data.mop, label: response.data.mop },
          otherExpenses: response.data.other_expenses,
          tax: { value: response.data.tax_type, label: response.data.tax_type },
          cash: response.data.cash,
          card: response.data.card,
          bank: { value: response.data.bank, label: response.data.bank },
          tenderedAmt: response.data.tendered_amt,
          balance: response.data.balance,
        }));
      } catch (error) {
        console.log(error);
      }
    };

    getInvoiceFooterData();
    // eslint-disable-next-line
  }, []);

  const handleMopChange = (selectedOption) => {
    updateFormData((prevData) => ({
      ...prevData,
      mop: selectedOption,
      cash: "",
      card: "",
      bank: null,
    }));
  };

  useEffect(() => {
    if (selectedDiscountType) {
      if (selectedDiscountType.value === "Discount %") {
        const discountOnTotal =
          (parseFloat(discountInput === "" ? 0 : discountInput) *
            parseFloat(totalAmt)) /
          100;
        updateFormData((prevData) => ({
          ...prevData,
          discountType: selectedDiscountType.value,
          discountInput: discountInput,
          discountOnTotal: discountOnTotal.toFixed(2),
        }));
      } else if (selectedDiscountType.value === "Discount Price") {
        updateFormData((prevData) => ({
          ...prevData,
          discountType: selectedDiscountType.value,
          discountInput: discountInput,
          discountOnTotal: parseFloat(
            discountInput === "" ? 0 : discountInput
          ).toFixed(2),
        }));
      }
    } else {
      updateFormData((prevData) => ({
        ...prevData,
        discountType: selectedDiscountType?.value || 0,
        discountInput: discountInput,
        discountOnTotal: 0.0,
      }));
    }
  }, [selectedDiscountType, discountInput, totalAmt, updateFormData]);

  return (
    <Fragment>
      <Row>
        <Col lg={6} className="mt-3">
          <Form.Group>
            <Form.Label>Discount</Form.Label>
            <Select
              value={selectedDiscountType}
              onChange={(selectedOption) => {
                setSelectedDiscountType(selectedOption);
                if (!selectedOption) {
                  setDiscountInput("");
                }
              }}
              options={discountOptions}
              placeholder="-- Select Type --"
              isSearchable={true}
              isClearable={true}
            />
          </Form.Group>
        </Col>
        <Col lg={6} className="mt-3">
          <Form.Group>
            <Form.Label>Discount Input</Form.Label>
            <Form.Control
              type="number"
              placeholder={"Enter Discount Input"}
              value={discountInput}
              onChange={(event) => setDiscountInput(event.target.value)}
              step="0.01"
              required
              readOnly={!selectedDiscountType}
            />
          </Form.Group>
        </Col>
        <Col lg={4} className="mt-3">
          <Form.Group>
            <Form.Label>Other Expenses</Form.Label>
            <Form.Control
              type="number"
              placeholder={"Enter Expense"}
              value={formData.otherExpenses}
              onChange={(event) =>
                updateFormData((prevData) => ({
                  ...prevData,
                  otherExpenses: event.target.value,
                }))
              }
              step="0.01"
            />
          </Form.Group>
        </Col>
        <Col lg={4} className="mt-3">
          <Form.Group>
            <Form.Label>Tax</Form.Label>
            <Select
              value={formData.tax}
              onChange={(selectedOption) => {
                updateFormData((prevData) => ({
                  ...prevData,
                  tax: selectedOption,
                }));
                handleTaxChange(selectedOption);
              }}
              options={taxOptions}
              placeholder="-- Select Tax --"
              isSearchable={true}
              isClearable={true}
            />
          </Form.Group>
        </Col>
        <Col lg={4}></Col>
        {/* <Col lg={4} className="mt-3">
          <Form.Group>
            <Form.Label>OS</Form.Label>
            <Form.Control
              type="number"
              value={formData.os}
              onChange={(event) =>
                updateFormData((prevData) => ({
                  ...prevData,
                  os: event.target.value,
                }))
              }
              step="0.01"
              readOnly={true}
            />
          </Form.Group>
        </Col> */}
        <Col lg={4} className="mt-3">
          <Form.Group>
            <Form.Label>MOP</Form.Label>
            <Select
              value={formData.mop}
              onChange={handleMopChange}
              options={mopOptions}
              placeholder="-- Select MOP --"
              isSearchable={true}
              isClearable={true}
            />
          </Form.Group>
        </Col>
        <Col lg={4} className="mt-3">
          <Form.Group>
            <Form.Label>Cash</Form.Label>
            <Form.Control
              type="number"
              value={formData.cash}
              onChange={(event) =>
                updateFormData((prevData) => ({
                  ...prevData,
                  cash: event.target.value,
                }))
              }
              step="0.01"
              readOnly={formData.mop && formData.mop.value !== "MIXED"}
            />
          </Form.Group>
        </Col>
        <Col lg={4} className="mt-3">
          <Form.Group>
            <Form.Label>Card</Form.Label>
            <Form.Control
              type="number"
              value={formData.card}
              onChange={(event) =>
                updateFormData((prevData) => ({
                  ...prevData,
                  card: event.target.value,
                }))
              }
              step="0.01"
              readOnly={formData.mop && formData.mop.value !== "MIXED"}
            />
          </Form.Group>
        </Col>
        <Col lg={4} className="mt-3">
          <Form.Group>
            <Form.Label>Bank</Form.Label>
            <Select
              value={formData.bank}
              onChange={(selectedOption) => {
                updateFormData((prevData) => ({
                  ...prevData,
                  bank: selectedOption,
                }));
              }}
              options={bankOptions}
              placeholder="-- Select Bank --"
              isSearchable={true}
              isClearable={true}
              isDisabled={formData.mop && formData.mop.value !== "MIXED"}
            />
          </Form.Group>
        </Col>
        <Col lg={4} className="mt-3">
          <Form.Group>
            <Form.Label>Cash given by Customer</Form.Label>
            <Form.Control
              type="number"
              value={formData.tenderedAmt}
              onChange={(event) =>
                updateFormData((prevData) => ({
                  ...prevData,
                  tenderedAmt: event.target.value,
                }))
              }
              step="0.01"
            />
          </Form.Group>
        </Col>
        <Col lg={4} className="mt-3">
          <Form.Group>
            <Form.Label>Balance</Form.Label>
            <Form.Control
              type="number"
              value={formData.balance}
              onChange={(event) =>
                updateFormData((prevData) => ({
                  ...prevData,
                  balance: event.target.value,
                }))
              }
              step="0.01"
              readOnly={true}
            />
          </Form.Group>
        </Col>
      </Row>
    </Fragment>
  );
};

export default SalesInvoiceFooterEditTable;
