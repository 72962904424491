import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { AuthProvider } from "./components/AuthContext";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import Master from "./pages/Master";
import ProductCategories from "./pages/ProductCategories";
import ProductDetails from "./pages/ProductDetails";
import Customers from "./pages/Customers";
import Vendors from "./pages/Vendors";
import Accounts from "./pages/Accounts";
import AccountGroup from "./pages/AccountGroup";
import LedgerGroup from "./pages/LedgerGroup";
import Payments from "./pages/Payments";
import Receipts from "./pages/Receipts";
import Billing from "./pages/Billing";
import Purchase from "./pages/Purchase";
import Settings from "./pages/Settings";
import Sales from "./pages/Sales";
import TrialBalance from "./pages/TrialBalance";
import ProfitAndLoss from "./pages/ProfitAndLoss";
import BalanceSheet from "./pages/BalanceSheet";
import Reports from "./pages/Reports";
import PurchaseReport from "./pages/PurchaseReport";
import SalesReport from "./pages/SalesReport";
import AccountsReports from "./pages/AccountsReports";
import LedgerReport from "./pages/LedgerReport";
import OutstandingCustomerReport from "./pages/OutstandingCustomerReport";
import PurchaseEdit from "./pages/PurchaseEdit";
import SalesEdit from "./pages/SalesEdit";
import PaymentReport from "./pages/PaymentReport";
import ReceiptReport from "./pages/ReceiptReport";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} exact />
            <Route path="/home" element={<Home />} exact />
            <Route path="/master" element={<Master />} exact />
            <Route
              path="/productCategories"
              element={<ProductCategories />}
              exact
            />
            <Route path="/productDetails" element={<ProductDetails />} exact />
            <Route path="/customers" element={<Customers />} exact />
            <Route path="/vendors" element={<Vendors />} exact />
            <Route path="/accounts" element={<Accounts />} exact />
            <Route path="/accountGroup" element={<AccountGroup />} exact />
            <Route path="/ledgerGroup" element={<LedgerGroup />} exact />
            <Route path="/payments" element={<Payments />} exact />
            <Route path="/receipts" element={<Receipts />} exact />
            <Route path="/billing" element={<Billing />} exact />
            <Route path="/purchase" element={<Purchase />} exact />
            <Route path="/settings" element={<Settings />} exact />
            <Route path="/sales" element={<Sales />} exact />
            <Route path="/trialBalance" element={<TrialBalance />} exact />
            <Route path="/profitAndLoss" element={<ProfitAndLoss />} exact />
            <Route path="/balanceSheet" element={<BalanceSheet />} exact />
            <Route path="/reports" element={<Reports />} exact />
            <Route path="/purchaseReport" element={<PurchaseReport />} exact />
            <Route path="/salesReport" element={<SalesReport />} exact />
            <Route
              path="/accountsReports"
              element={<AccountsReports />}
              exact
            />
            <Route path="/ledgerReport" element={<LedgerReport />} exact />
            <Route
              path="/outstandingCustomerReport"
              element={<OutstandingCustomerReport />}
              exact
            />
            <Route
              path="/purchaseEdit/:invoice_no"
              element={<PurchaseEdit />}
              exact
            />
            <Route
              path="/salesEdit/:invoice_no"
              element={<SalesEdit />}
              exact
            />
            <Route path="/paymentReport" element={<PaymentReport />} exact />
            <Route path="/receiptReport" element={<ReceiptReport />} exact />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
