import { useEffect, useState, Fragment, useRef } from "react";
import axios from "axios";
import { API_BASE_URL } from "../config";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { Button } from "react-bootstrap";

const PurchaseDayBookDetail = ({ formData }) => {
  const tableRef = useRef(null);
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleString().replace(/[/:]/g, "_");
  const [dayBookDetailData, setDayBookDetailData] = useState([]);

  useEffect(() => {
    const getPurchaseDayBookDetailData = async () => {
      try {
        const fromDateStr = formData.fromDate.toISOString().split("T")[0];
        const toDateStr = formData.toDate.toISOString().split("T")[0];
        const response = await axios.get(
          `${API_BASE_URL}/api/getPurchaseDayBookDetailData?fromDate=${fromDateStr}&toDate=${toDateStr}&customerName=${formData.name}`
        );
        setDayBookDetailData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getPurchaseDayBookDetailData();
  }, [formData]);

  const totalSum = dayBookDetailData.reduce(
    (acc, row) => {
      acc.total_amount = isNaN(parseFloat(row.total_amount))
        ? acc.total_amount
        : (
            parseFloat(acc.total_amount) + parseFloat(row.total_amount)
          ).toString();
      acc.discount_on_total = isNaN(parseFloat(row.discount_on_total))
        ? acc.discount_on_total
        : (
            parseFloat(acc.discount_on_total) +
            parseFloat(row.discount_on_total)
          ).toString();
      acc.round_off = isNaN(parseFloat(row.round_off))
        ? acc.round_off
        : (parseFloat(acc.round_off) + parseFloat(row.round_off)).toString();
      acc.bill_amount = isNaN(parseFloat(row.bill_amount))
        ? acc.bill_amount
        : (
            parseFloat(acc.bill_amount) + parseFloat(row.bill_amount)
          ).toString();
      acc.height = isNaN(parseFloat(row.height))
        ? acc.height
        : (parseFloat(acc.height) + parseFloat(row.height)).toString();
      acc.width = isNaN(parseFloat(row.width))
        ? acc.width
        : (parseFloat(acc.width) + parseFloat(row.width)).toString();
      acc.qty = isNaN(parseFloat(row.qty))
        ? acc.qty
        : (parseFloat(acc.qty) + parseFloat(row.qty)).toString();
      acc.total_sqft = isNaN(parseFloat(row.total_sqft))
        ? acc.total_sqft
        : (parseFloat(acc.total_sqft) + parseFloat(row.total_sqft)).toString();
      acc.gross_amt = isNaN(parseFloat(row.gross_amt))
        ? acc.gross_amt
        : (parseFloat(acc.gross_amt) + parseFloat(row.gross_amt)).toString();
      acc.taxable = isNaN(parseFloat(row.taxable))
        ? acc.taxable
        : (parseFloat(acc.taxable) + parseFloat(row.taxable)).toString();
      acc.discount = isNaN(parseFloat(row.discount))
        ? acc.discount
        : (parseFloat(acc.discount) + parseFloat(row.discount)).toString();
      acc.sub_total = isNaN(parseFloat(row.sub_total))
        ? acc.sub_total
        : (parseFloat(acc.sub_total) + parseFloat(row.sub_total)).toString();
      return acc;
    },
    {
      total_amount: "0",
      discount_on_total: "0",
      round_off: "0",
      bill_amount: "0",
      height: "0",
      width: "0",
      qty: "0",
      total_sqft: "0",
      gross_amt: "0",
      taxable: "0",
      discount: "0",
      sub_total: "0",
    }
  );

  return (
    <Fragment>
      <DownloadTableExcel
        filename={"PurchaseDayBookDetailReport_" + formattedDate}
        sheet="PurchaseDayBookDetail_Report"
        currentTableRef={tableRef.current}
      >
        <Button> Export excel </Button>
      </DownloadTableExcel>
      <div className="table-responsive">
        <table
          ref={tableRef}
          className="table table-bordered table-hover table-striped table-info mt-3"
        >
          <thead>
            <tr>
              <th>Invoice No</th>
              <th>Date</th>
              <th>Vendor Name</th>
              <th>Discount Type</th>
              <th>Discount Input</th>
              <th>Other Expenses</th>
              <th>MOP</th>
              <th>Tax Type</th>
              <th>OS</th>
              <th>Total Amount</th>
              <th>Discount On Total</th>
              <th>Round Off</th>
              <th>Bill Amount</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>Product Code</th>
              <th>Product Name</th>
              <th>Height</th>
              <th>Width</th>
              <th>Qty</th>
              <th>Total Sqft</th>
              <th>Purchase Price</th>
              <th>Sales Price</th>
              <th>MRP</th>
              <th>Gross Amt</th>
              <th>Taxable</th>
              <th>CGST %</th>
              <th>CGST</th>
              <th>SGST %</th>
              <th>SGST</th>
              <th>IGST %</th>
              <th>IGST</th>
              <th>Discount %</th>
              <th>Discount</th>
              <th>Sub Total</th>
            </tr>
          </thead>
          <tbody>
            {dayBookDetailData.map((row, index) => (
              <tr key={index}>
                <td>{row.invoice_no}</td>
                <td>{row.invoice_date}</td>
                <td>{row.customer_name}</td>
                <td>{row.discount_type}</td>
                <td>{row.discount_input}</td>
                <td>{row.other_expenses}</td>
                <td>{row.mop}</td>
                <td>{row.tax_type}</td>
                <td>{row.os}</td>
                <td>{row.total_amount}</td>
                <td>{row.discount_on_total}</td>
                <td>{row.round_off}</td>
                <td>{row.bill_amount}</td>
                <td>
                  {row.createdAt && new Date(row.createdAt).toLocaleString()}
                </td>
                <td>
                  {row.updatedAt && new Date(row.updatedAt).toLocaleString()}
                </td>
                <td>{row.product_code}</td>
                <td>{row.product_name}</td>
                <td>{row.height}</td>
                <td>{row.width}</td>
                <td>{row.qty}</td>
                <td>{row.total_sqft}</td>
                <td>{row.purchase_price}</td>
                <td>{row.sales_price}</td>
                <td>{row.mrp}</td>
                <td>{row.gross_amt}</td>
                <td>{row.taxable}</td>
                <td>{row.cgstP}</td>
                <td>{row.cgst}</td>
                <td>{row.sgstP}</td>
                <td>{row.sgst}</td>
                <td>{row.igstP}</td>
                <td>{row.igst}</td>
                <td>{row.discountP}</td>
                <td>{row.discount}</td>
                <td>{row.sub_total}</td>
              </tr>
            ))}
            <tr>
              <th colSpan={9}>Total</th>
              <th>{Number(totalSum.total_amount).toFixed(2)}</th>
              <th>{Number(totalSum.discount_on_total).toFixed(2)}</th>
              <th>{Number(totalSum.round_off).toFixed(2)}</th>
              <th>{Number(totalSum.bill_amount).toFixed(2)}</th>
              <th colSpan={4}></th>
              <th>{Number(totalSum.height).toFixed(2)}</th>
              <th>{Number(totalSum.width).toFixed(2)}</th>
              <th>{Number(totalSum.qty).toFixed(2)}</th>
              <th>{Number(totalSum.total_sqft).toFixed(2)}</th>
              <th colSpan={3}></th>
              <th>{Number(totalSum.gross_amt).toFixed(2)}</th>
              <th>{Number(totalSum.taxable).toFixed(2)}</th>
              <th colSpan={7}></th>
              <th>{Number(totalSum.discount).toFixed(2)}</th>
              <th>{Number(totalSum.sub_total).toFixed(2)}</th>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default PurchaseDayBookDetail;
